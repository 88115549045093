.activity-routine {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-left: 8px solid #8e24aa;
  border-radius: 0.5rem;
  gap: 0.5rem;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.activity-task {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-left: 8px solid #613cb0;
  border-radius: 0.5rem;
  gap: 0.5rem;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.activity-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 296px;
  margin: 8px 0;
  padding: 0.8rem 1rem 0 1rem;
}

.small-bard-icon {
  width: 16px;
  height: 16px;
  color: white;
}

.activity-row-2 {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    // flex-direction: row; 
    // flex-wrap: wrap;
  }
  margin-left: 1rem;
  padding: 0.8rem 1rem 0 1rem;

  .activityLeft {
    background: #1c1b1f0a;
    width: fit-content;
    height: fit-content;
    border-radius: 0.5rem;
    color: #3c4043;

    .gapS {
      gap: 0.5rem;
    }

    .custom-progress .ant-progress-inner {
      background-color: blue;
    }

  

    .activityPadding {
      padding: 0 0.8rem;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      color: #3c4043;
    }

    &:nth-child(3) {
      margin-left: auto;
      margin-right: 1rem;
      background: transparent;
    }

    .ant-btn-group {
      display: flex;
      border-radius: 50px;
      overflow: hidden;
      width: 4rem;
      margin: 0;

      .divideLine {
        border-left: 1px solid #8e24aa;
      }

      .ant-btn {
        background: #f1e5f4;
        flex: 1;
        border-radius: 0;
        border: none;
        color: #8e24aa;

        &:hover {
          background: #f1e5f4;
          color: #8e24aa;
        }

        &:last-child {
          border-right: none; // Removes the right border from the last button
        }
      }
    }
  }
}

.activeFlag {
  fill: #4b4a4d;
}

.activity-row-left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.activity-row-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.is-priority {
  background: #3c40430a;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.menu-button-activity {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
  border-radius: 10rem;
  --ion-color-primary: #3c40430a;
  color: #2b2930;
  cursor: pointer;
  width: fit-content;
}

.activity-row-titles {
  // margin-left: 0.5rem;
}

.task-title {
  overflow: hidden;
  max-height: 21px; // This aligns with line-height: 1.5 for font-size: 14px; if line-height or font-size changes, max-height will need to be updated.
  color: #2b2930;
  font-weight: 600;
}

.task-complete-title {
  overflow: hidden;
  max-height: 21px; // This aligns with line-height: 1.5 for font-size: 14px; if line-height or font-size changes, max-height will need to be updated.
  color: #2b2930;
  font-weight: 600;
  text-decoration: line-through;
}

.routine-title {
  overflow: hidden;
  max-height: 21px; // This aligns with line-height: 1.5 for font-size: 14px; if line-height or font-size changes, max-height will need to be updated.
  cursor: pointer;

  &:hover {
    color: var(--ion-color-primary);
  }
}

.flag-box {
  margin: 0 1rem;
}

.task-complete {
  text-decoration: line-through;
}

.routine-complete {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  min-width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10.2px;
  color: white;
}

.extra-routine {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  min-width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
  background-color: #8e24aa;
  border: 0;

  p {
    margin: 0;
  }
}

.task-check-btn {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  min-width: 28px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10.2px;
  color: white;
}

.activity-row-goal {
  overflow: hidden;
  font-size: 12px;
  max-height: 18px; // This aligns with line-height: 1.5 for font-size: 12px; if line-height or font-size changes, max-height will need to be updated.
}

.date {
  color: #3c4043;
  margin: 0 16px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ant-btn-group {
  white-space: nowrap;
  margin: 0 16px;
}

.activity-row-icons {
  white-space: nowrap;
}

.activity-row-icon {
  padding: 4px;
}

// .primary-text {
//   color: var(--ion-color-primary) !important;
// }

.over-due-date {
  color: #ff7d6b;
}

@media only screen and (max-width: 435px) {
  /* For mobile phones (iphone6/7/8 plus): */
  [class*="date"] {
    margin: 0 6px;
  }

  [class*="activity-row-left"] {
    max-width: 220px;
  }
}

@media only screen and (max-width: 404px) {
  /* For mobile phones (iphone6/7/8): */
  [class*="activity-row-left"] {
    max-width: 200px;
  }
}

@media only screen and (max-width: 375px) {
  /* For mobile phones (iphone6/7/8): */
  [class*="activity-row-left"] {
    max-width: 200px;
  }
}

.popover {
  margin-left: -4rem;
  background: transparent;
}

.edit {
  display: flex;
  margin-left: -1rem;
  margin-right: auto;
  gap: 0.8rem;
  color: white;
}

.delete {
  display: flex;
  margin-left: -0.9rem;
  margin-right: auto;
  color: #ff7d6b;
  gap: 0.8rem;

  p {
    margin: 0 0 0 0.5rem;
  }
}

.button-native {
  &:hover {
    color: black;
  }
}

.activity-routine:hover {
  cursor: pointer;

  .activity-row-titles {
    .task-title {
      color: #ff7e1d;
    }

    .task-complete-title {
      color: #ff7e1d;
    }
  }
}

// mirrors masonry grid styling by centering active goal cards (when theres only 2) directly under arrow and putting a total of 25px padding between them
.give-padding {
  margin-left: -10px;
  margin-right: 15px;
}

//recenters cards for mobile view
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="give-padding"] {
    margin-left: 0;
    margin-right: 0;
  }
}

.controls-container{
  display: flex;
  align-items: center;
  gap: 1rem;
  .menu-container{
    display: flex;
    align-items: center;
  }
}

.no-margin {
  margin: 0;
  width: fit-content;
  display: flex;
  align-items: center;
  // justify-content: center;

  .shared-title{
    width: 90% !important;
    min-width: 120px !important;
  }
  .title {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    min-width: 150px;
    width: 100%;
  }
}

.no-background {
  --background: transparent !important;

  &:hover {
    --background: transparent !important;
  }
}

.card-menu-title {
  display: flex !important;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.kebabMenu {
  margin-left: auto;
  z-index: 9999;
  min-height: 24px;
}

.small-width {
  width: fit-content !important;
  --padding-start: 0;
  --padding-end: 0;
  --ion-color-primary: #fff;
  --ion-background-color: #413d45;
  padding-inline-end: 0;

  .item-native {
    .item-inner {
      padding-left: 0;
      padding-right: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }
}

.goal-card {
  border: 0;
  background: #fafafa;
  box-shadow: 0px 1px 2px 0px #1018280d;

}
.shared {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background: #f8ba121a;
  color: #ff7e1d;
}

.goal-card:hover {
  cursor: pointer;
  .card-menu-title {
    .title {
      color: #ff7e1d;
    }
  }
}

.menu-button {
  background: transparent;
  color: #2b2930;
  --ion-color-primary: transparent;
}

.in-active-goals {
  filter: grayscale(100%);
}

.support-container {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  height: 100%;
}

.support-intro-text {
  width: 80%;
  padding-top: 2em;
  ul > li {
    list-style: disc;
  }
}

.center-text {
  text-align: center;
}

.open-sans {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar-chart-icon {
  margin-top: 120px;
  margin-bottom: 12px;
  font-size: 80px;
  color: var(--ion-color-medium);
}

.completed-goal-card {
  background: #ffc773;
  border-top: 3px solid #ffc773 !important;

  .kebab-Menu {
    margin-left: auto;
    z-index: 9999;
    margin-top: 0.25rem;
  }

  .shared {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: #ff7e1d;
  }
}

.goal-completed-on {
  font-size: 0.8em !important;
  width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blue-hover {
  &:hover {
    color: var(--ion-color-tertiary) !important;
  }
}

.give-padding {
  margin-left: -10px;
  margin-right: 15px;

  //recenters the cards for mobile view - 2 card wrapper will off-center cards in mobile view
  @media only screen and (max-width: 768px) {

    /* For mobile phones: */
    [class*="give-padding"] {
      margin-left: 0;
      margin-right: 0;
    }
  }

  // impersonates masonry layout styling when only 2 cards to avoid masonry bug
  .completed-goal-card-wrapper-own-margin {
    width: 300px;
    margin: 0 25px 25px 0;
    padding: 3px 0 0;
    background: linear-gradient(90deg,
        rgba(239, 179, 157, 1) 0%,
        rgba(255, 222, 110, 1) 100%);
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .card-menu-title-completed {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .no-margin {
      margin: 0;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        width: 100%;
      }
    }
  }

  //recenters the cards for mobile view - 2 card wrapper will off-center cards in mobile view
  @media only screen and (max-width: 768px) {

    /* For mobile phones: */
    [class*="completed-goal-card-wrapper-own-margin"] {
      margin: 0 0 25px 0;
    }
  }
}
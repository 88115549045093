label {
  cursor: pointer;
  position: relative; /* Needed for proper positioning */
}

input[type="checkbox"] {
  opacity: 0; /* Hide the actual checkbox */
  width: 0;
  height: 0;
}

.task-round, .routine-round {
  width: 28px;
  height: 28px;
  border-radius: 50%; /* Ensures a circular shape */
  border: 1px solid #613CB0;
  display: inline-block;
  vertical-align: middle; /* Aligns checkbox with label text */
  position: relative;
}

input[type="checkbox"]:checked + .task-round {
  background-color: #613CB0;
  border-color: #613CB0;
}

input[type="checkbox"]:checked + .routine-round {
  background-color: #8E24AA;
  border-color: #8E24AA;
}

/* Optional: Style the checkmark (using pseudo-element) */
.task-round:after, .routine-round:after {
  content: "";
  display: none; /* Initially hide the checkmark */
  position: absolute;
  top: 45%;
  left: 50%;
  width: 12px;
  height: 6px;
  border: solid white;
  border-width: 0 0 2px 2px;
  transform: translate(-50%, -50%) rotate(-45deg);
}

input[type="checkbox"]:checked + .task-round:after,
input[type="checkbox"]:checked + .routine-round:after {
  display: block; /* Show the checkmark when checked */
}

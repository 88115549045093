.dashboard-header {
  // makes header elements aligned and spaced
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2.6rem;
  gap: 1rem;
}

.content-container {
  margin: 0 1rem;
}
.content-container-actions {
  margin: 0 2rem;
  @media screen and (max-width: 768px) {
    margin: 0rem;
  }

}

.dashboard-row {
  display: flex;
  gap: 2rem;
}

.SiteHeaderSM {
  font-size: 0.9rem;
  font-weight: 600;
  align-items: center;
}

.dashboard-column {
  width: 48%;
  padding: 0.3em;
  margin-top: 1em; // provides spacing when cols stacked vertically
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .dashboard-row {
    flex-direction: column;
    gap: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .more-padding {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 414px) {
  /* For mobile phones (iphone6/7/8 plus): */

  [class*="width-control"] {
    max-width: 18rem;
    overflow-x: scroll;
    justify-content: space-between !important;
  }
}

@media only screen and (max-width: 375px) {
  /* For mobile phones (iphone6/7/8): */
  [class*="width-control"] {
    max-width: 15.5rem;
    justify-content: space-between !important;
  }
}

.add-button {
  color: white;
  background: black;
  --ion-color-primary: transparent;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  position: relative;

  .create-button {
    padding: 0.5rem;
    background-color: #0d6ef9;
    display: flex;
    width: fit-content;
    align-items: center;
    color: white;
    border-radius: 8rem;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
    transform: rotateZ(45deg);
    transition: all 0.3s;

    .button-space {
      margin-left: 0.5rem;
    }
  }

  .cross {
    transform: rotateZ(0deg);
    background: #ff7d6b;
    transition: all 0.3s;
  }

  .buttons {
    position: absolute;
    top: 2.5rem;
    left: -5.6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 0.5rem 1rem 0.5rem;
    border-radius: 1rem;

    .custom-button {
      --background: #0d6ef9;
      --color: #fff;
    }

    .plus-sign {
      margin-right: auto;
    }
  }
}

.in-view {
  animation: moveDown 0.5s;
  margin-left: auto;
}

@keyframes moveDown {
  from {
    transform: translateY(-1rem);
  }

  to {
    transform: translateY(0);
  }
}

.width-control {
  justify-content: flex-end;
  span {
    font-size: 0.8rem;

    .segment-button-checked {
      border-radius: 8rem;
      background: #e9e7e4;
      color: #413d45;
      border: none !important;
      --indicator-color: none !important;
    }

    .in-segment {
      border-radius: 8rem;
      --background-focused: transparent !important;
      --background-hover: transparent !important;
    }
  }
}

.more-padding {
  padding: 0 4rem;
}

.flexBox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.zIndex {
  z-index: 50;
}

.segment-activated {
  padding: 0.5rem;
  border-radius: 8rem;
  background: transparent !important;
}

.capitalize {
  text-transform: capitalize;
}

.overflow-y-scroll {
  overflow-y: auto;
}

ion-segment-button.segment-button-checked {
  --background: none !important;
  /* Remove default background */
}

.segment-button-activated {
  --background: none !important;
  /* Remove default background */
}

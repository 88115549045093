.goal-timeline {
  margin: 3rem auto;
  display: flex;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.routine-container {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.desc {
  font-size: 0.9rem;
  font-weight: 400;
  margin: 1rem 0.5rem;
  text-wrap: wrap;
}

.ant-select-selector {
  border-radius: 10rem !important;
  padding: 0 1.1rem !important;
  max-height: 30px;
}

.ant-select-arrow {
  color: black;
}

.gapS {
  gap: 0.5rem !important;
  border-radius: 10rem;
}

.active {
  color: #ffc773;
}

.active-priority {
  color: black;
  font-weight: 500;
  position: relative;
  border: 1px solid #ffc773;
  border-radius: 20px;
  height: 32px;
  padding: 0 1rem;
}

.priority-btn-active {
  border: 1px solid black;
  color: black;
}

.priority-btn-inactive {
  border: 1px solid #d9d9d9;
  color: #939597;
}

.priority-btn {
  position: relative;
  border-radius: 20px;
  height: 32px;
  padding: 0 1rem;
  background-color: #fff;
}
.min-h-40vh {
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priority-text {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #000000d9;
}

.status-active {
  color: #000000d9;
  border: 1px solid #000000d9;
  border-radius: 20px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}

.status-completed {
  color: black;
  border-radius: 20px;
  background-color: #ffc773;
}

.status-inactive {
  color: gray;
  border: 1px solid lightgray;
  border-radius: 20px;
}

.priority-btn:hover {
  background-color: transparent;
}

.goalNameInput {
  width: 100%;
}

.no-button {
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
}

.shared-btn {
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.flex-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    color: #2b2930;
  }
  .title-write {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    color: #2b2930;
    height: 100%;
    size: 100%;
    text-wrap: wrap;
    line-height: 32px;
  }
}

.complete-goal-review-card {
  border-top: none !important;

  .ant-card-head {
    background: linear-gradient(90deg, rgba(239, 179, 157, 1) 0%, rgba(255, 222, 110, 1) 100%) !important;
    opacity: 0.7;
  }
}

.review-card-container {
  background: #FAFAFA;
  box-shadow: 0px 1px 2px #1018280D;
  padding: 1.5rem;
  border-radius: 0.5rem;

  .review-goal-card-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #2B2930;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.activities-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}
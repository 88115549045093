.check-in-steps {
  margin: 2rem 0;
}

.check-in-card-wrapper {
  margin: 2rem auto 0 auto;
  max-width: 850px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.check-in-divider {
  margin: 2.5rem 0;
}

.check-in-next-btn {
  margin: 1rem;
  svg {
    margin-right: 8px;
  }
}

.ci-step-three-form {
  width: 500px;
  margin: 2rem auto 0 auto;
}
.auth-options {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 12px auto;
  a {
    width: 100%;
    color: #667085;
    span{
      color: #FF7E1D;
    }
  }
}

.m-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.m-x-auto{
  margin-left: auto;
  margin-right: auto;
}

.form-item {
  text-align: left;
  display: flex;
  gap: 0.5rem;
}

.sign-in-header {
  margin-bottom: 12px !important;
  color:rgba(0, 0, 0, 1);
  font-weight: 600;
  font-size: 2rem;
}
.sign-in-subheader {
  margin-bottom: 12px !important;
  color:#4B4A4D;
  font-weight: 400;
  font-size: 1rem;
}

.label-sign-in {
  color: #344054;
  font-size: 0.75rem;
  font-weight: 500;
}

.login-form{
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;
}

.input-sign-in{
  border-radius: 8px;
}
.sign-in-button{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mb-0{
  margin-bottom: 0 !important;
}

.google-button{
  background: #fff;
  border: 1px solid #D0D5DD;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  border-radius: 8px;
  gap: 1rem;
  width: 100%;
}
.my-1{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.no-border{
  border: none;
}
.border{
  border: 1px solid #D0D5DD;
}
.padding-around{
  padding: 0.5rem 1rem 0.5rem 0rem;
}
.access-code-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  
    .form-container {
      width: 100%;
      max-width: 400px;
      padding: 2rem;
      background: #ffffff;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      text-align: center;
    }
  
    .access-code-header {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  
    .access-code-subheader {
      font-size: 16px;
      color: #6c757d;
      margin-bottom: 1.5rem;
    }
  
    .input-container {
      margin-bottom: 1rem;
  
      .input-access-code {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border-radius: 5px;
      }
    }
  
    .button-container {
      margin-top: 1rem;
  
      .form-submit-button {
        width: 100%;
        font-size: 16px;
        padding: 20px;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
      }
    }
  }
  
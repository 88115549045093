.check-in-results {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .ant-collapse {
    width: 100%;
    max-width: 800px;
    min-width: 400px;
    margin: auto;
  }

  .check-in-result {
    background: #FAFAFA;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .check-in-title {
      font-size: 1rem;
      font-weight: 600;
      color: #413D45;
    }

    .check-in-description {
      font-size: 1rem;
      font-weight: 400;
      color: #79777C;
      white-space: pre-wrap;
    }
  }
}

.steps-complete {
  max-width: 800px;
  min-width: 400px;
  margin: 2.5rem auto auto auto;
}
.actions-container{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.occurence-container{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.counter-container{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.goal-routines,
.goal-task {
    margin-top: 24px;
    display: inline-block;
}

.goal-routines-add-btn,
.goal-task-add-btn {
    margin-top: 18px;
    margin-bottom: 1em;
    display: inline-block;
    float: right;
}

.title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 24px;

    .add-btn{
        padding: 0;
        background: transparent;
    }
}
/* Google Fonts */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap"); // Open Sans
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap"); // Montserrat
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap"); // Montserrat

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root {
  --ion-font-family: "Inter", sans-serif;
  font-family: "Inter", sans-serif;
  font-weight: 400;

  ::selection {
    background-color: #37343d;
    color: #fff;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

/* New Color Creator: https://ionicframework.com/docs/theming/colors */

:root {
  /** primary **/
  --ion-color-primary: #2b2930;
  --ion-color-primary-rgb: 255, 87, 71;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2b2930;
  --ion-color-primary-tint: #2b2930;

  /** secondary **/
  --ion-color-secondary: #2b2930cc;
  --ion-color-secondary-rgb: 255, 179, 71;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #e09e3e;
  --ion-color-secondary-tint: #ffbb59;

  /** tertiary **/
  --ion-color-tertiary: #4793ff;
  --ion-color-tertiary-rgb: 71, 147, 255;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #3e81e0;
  --ion-color-tertiary-tint: #599eff;

  /** quarternary **/
  --ion-color-quarternary: #4f4df2;
  --ion-color-quarternary-rgb: 79, 77, 242;
  --ion-color-quarternary-contrast: #fff;
  --ion-color-quarternary-contrast-rgb: 255, 255, 255;
  --ion-color-quarternary-shade: #4f4df2;
  --ion-color-quarternary-tint: #4f4df2;

  // Courtney Schild to reconsider: /** success **/
  --ion-color-success: #52c41a;
  --ion-color-success-rgb: 82, 196, 26;
  --ion-color-success-contrast: #000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #48ac17;
  --ion-color-success-tint: #63ca31;

  // Courtney Schild to reconsider: /** warning **/
  --ion-color-warning: #faad14;
  --ion-color-warning-rgb: 250, 173, 20;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dc9812;
  --ion-color-warning-tint: #fbb52c;

  // Courtney Schild to reconsider: /** danger **/
  --ion-color-danger: #e75d4a;
  --ion-color-danger-rgb: 245, 34, 45;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e75d4a;
  --ion-color-danger-tint: #e75d4a;

  /** dark **/
  --ion-color-dark: #323232;
  --ion-color-dark-rgb: 50, 50, 50;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #2c2c2c;
  --ion-color-dark-tint: #474747;

  /** medium-dark **/
  --ion-color-medium-dark: #646464;
  --ion-color-medium-dark-rgb: 100, 100, 100;
  --ion-color-medium-dark-contrast: #fff;
  --ion-color-medium-dark-contrast-rgb: 255, 255, 255;
  --ion-color-medium-dark-shade: #585858;
  --ion-color-medium-dark-tint: #747474;

  /** medium **/
  --ion-color-medium: #969696;
  --ion-color-medium-rgb: 150, 150, 150;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #848484;
  --ion-color-medium-tint: #a1a1a1;

  /** medium-light **/
  --ion-color-medium-light: #f4f3fe;
  --ion-color-medium-light-rgb: 200, 200, 200;
  --ion-color-medium-light-contrast: #000;
  --ion-color-medium-light-contrast-rgb: 0, 0, 0;
  --ion-color-medium-light-shade: #b0b0b0;
  --ion-color-medium-light-tint: #cecece;

  /** light **/
  --ion-color-light: #fafafa;
  --ion-color-light-rgb: 250, 250, 250;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dcdcdc;
  --ion-color-light-tint: #fbfbfb;
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary);
  --ion-color-base-rgb: var(--ion-color-secondary-rgb);
  --ion-color-contrast: var(--ion-color-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-shade);
  --ion-color-tint: var(--ion-color-secondary-tint);
}

.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary);
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary-shade);
  --ion-color-tint: var(--ion-color-tertiary-tint);
}

.ion-color-quarternary {
  --ion-color-base: var(--ion-color-quarternary);
  --ion-color-base-rgb: var(--ion-color-quarternary-rgb);
  --ion-color-contrast: var(--ion-color-quarternary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quarternary-contrast-rgb);
  --ion-color-shade: var(--ion-color-quarternary-shade);
  --ion-color-tint: var(--ion-color-quarternary-tint);
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark);
  --ion-color-base-rgb: var(--ion-color-dark-rgb);
  --ion-color-contrast: var(--ion-color-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-shade);
  --ion-color-tint: var(--ion-color-dark-tint);
}

.ion-color-medium-dark {
  --ion-color-base: var(--ion-color-medium-dark);
  --ion-color-base-rgb: var(--ion-color-medium-dark-rgb);
  --ion-color-contrast: var(--ion-color-medium-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-dark-shade);
  --ion-color-tint: var(--ion-color-medium-dark-tint);
}

.ion-color-medium {
  --ion-color-base: var(--ion-color-medium);
  --ion-color-base-rgb: var(--ion-color-medium-rgb);
  --ion-color-contrast: var(--ion-color-medium-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-shade);
  --ion-color-tint: var(--ion-color-medium-tint);
}

.ion-color-medium-light {
  --ion-color-base: var(--ion-color-medium-light);
  --ion-color-base-rgb: var(--ion-color-medium-light-rgb);
  --ion-color-contrast: var(--ion-color-medium-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-light-shade);
  --ion-color-tint: var(--ion-color-medium-light-tint);
}

.ion-color-light {
  --ion-color-base: var(--ion-color-light);
  --ion-color-base-rgb: var(--ion-color-light-rgb);
  --ion-color-contrast: var(--ion-color-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-shade);
  --ion-color-tint: var(--ion-color-light-tint);
}

/* Grid */

:root {
  --space-unit: 16px;
  --space-xxs: calc(0.25 * var(--space-unit)); // 4px
  --space-xs: calc(0.5 * var(--space-unit)); // 8px
  --space-sm: calc(0.75 * var(--space-unit)); // 12px
  --font-sm: 0.85rem; // 14px
  --space-md: calc(1.25 * var(--space-unit)); // 20px
  --space-mdlg: calc(1.5 * var(--space-unit)); // 24px
  --space-lg: calc(2 * var(--space-unit)); // 32px
  --space-xl: calc(3 * var(--space-unit)); // 48px
  --space-xxl: calc(4 * var(--space-unit)); // 64px
}

/* This will affect the scrollbar globally */
* {
  scrollbar-width: thin;
  scrollbar-color: #413d45 #fafafa;
}

/* For Webkit browsers like Chrome, Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #fafafa;
}

*::-webkit-scrollbar-thumb {
  background-color: #413d45;
  border-radius: 20px;
  border: 3px solid #fafafa;
}

// Courtney Schild to reconsider: /* Typography */

.mont {
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 600; // Montserrat Medium
}

.montX {
  font-family: "Inter", sans-serif;
  letter-spacing: 0.1rem;
  font-weight: 600; // Montserrat Medium
}

.label {
  color: #4b4a4d;
  font-size: var(--font-sm);
}

.ant-legacy-form-item {
  margin-bottom: 1rem;
}

.mlr-auto {
  margin: 0 auto;
}

.w-m-850 {
  max-width: 1100px;
}

.mt-2 {
  margin-top: 2rem;
}

.SiteHeader {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-weight: 600;
  flex-grow: 1;
}

.XL {
  font-size: 18px;
}

.L {
  font-size: 16px;
}

.M {
  font-size: 0.9rem;
}

.S {
  font-size: 10px;
}

.formLabel {
  margin-bottom: 0px;
}

.team-name {
  color: #000000;
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

.small-title {
  font-weight: bold;
}

.subtitle {
  color: var(--ion-color-medium);
}

.inline-alert {
  display: inline-block;
}

.longform-text {
  width: 50%;
  background-color: var(--ion-color-light);
  padding: 2%;
  margin: 24px auto;
}

.bold-text {
  font-weight: bold;
}

.privacy-term-page {
  width: 100%;
  // background-color: var(--ion-color-light);
  padding: 2%;
  margin: 48px auto;
  a {
    color: blue;
  }
  a:hover {
    color: blue;
    text-decoration: underline;
  }
  hr {
    background-color: #ccc;
    margin: 28px auto;
  }
  code {
    color: #198138;
  }
  ol,
  ul {
    margin: 8px auto;
  }
  ul > li {
    list-style: disc;
    list-style-type: disc;
  }
  ol > li {
    list-style: decimal;
    list-style-type: decimal;
  }
  ol > li::marker {
    font-weight: 500;
  }
  .font-bold {
    font-weight: bold;
  }
}
@media only screen and (min-width: 992px) {
  .privacy-term-page {
    width: 70%;
    margin: 24px auto;
  }
}
@media only screen and (min-width: 1360px) {
  .privacy-term-page {
    width: 50%;
  }
}

ion-toolbar {
  --background: var(--ion-color-primary);
  --padding-start: var(--space-lg);
  --padding-end: var(--space-lg);
  height: var(--space-xxl);
  display: flex;
}

.delete-popover {
  border-radius: 0.5rem;
}

.no-background {
  display: flex;
  gap: 1rem;
}

.ant-result-extra {
  margin-top: 1rem;
}

.ant-result {
  padding: 2rem;
}

.goal-modal {
  --width: 450px;
  --height: 410px;
}

.new-goal-modal {
  --width: 450px;
  --height: 350px;
}

.goal-modal-complete {
  --width: 450px;
  --height: 460px;
}

.goal-review-card {
  background-color: #fafafa;
  max-width: 850px;
  border-radius: 0.5rem;
  border: none;
}

.activity-modal {
  --width: 450px;
  --height: 400px !important;
}

.routine-modal {
  --width: 450px;
  --height: 450px !important;
}

.routine-progress-modal {
  --height: 525px !important;
  @media screen and (max-width: 768px) {
    --width: 90vw !important; 
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

ion-select::part(icon) {
  display: none !important;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* UserProfile.tsx */
  .profile-card {
    width: 80%;
    padding: 2rem;
    border-radius: 0.5rem;
  }

  .small-profile-card {
    width: 80%;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Set variables of the entire app for iOS and Material Design */

.ios,
.md {
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff;
}

.header-content-wrapper {
  align-items: center;
}

ion-title {
  padding: 0;
}

ion-page {
  background-color: #ffffff;
  padding: 0 var(--space-mdlg); //unnecessary?
  min-height: 700px;
}

.scroll-page {
  overflow-y: scroll;
}

.dashboard {
  padding: 0 var(--space-mdlg);
}

a {
  color: var(--ion-color-medium-dark);

  &:hover {
    color: var(--ion-color-primary);
  }
}

.primary-link {
  color: var(--ion-color-primary);

  &:hover {
    color: var(--ion-color-medium-dark);
  }

  font-family: "Inter", sans-serif;
}

.navbar-link {
  color: var(--ion-toolbar-color);
  font-family: "Inter", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 20px;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--ion-toolbar-color);
  }
}

// Other

.celebration-gif {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0.5;
  width: calc(6.25 * var(--space-xxl)); //400px
}

.goalden-avatar {
  color: var(--ion-color-primary);
  background-color: #ffddda;
}

.menu-content {
  background: #fafafa;
  transform: none; // Override ionic transform style, which caused mobile bug: https://github.com/ionic-team/ionic/blob/0d40d3f3b72aac7932ac71e6573d8bbb65a01515/core/src/css/core.scss#L154
}

// Buttons

ion-button {
  --border-radius: 8rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-size: 12px;
  text-transform: none;
  --padding-top: 1.25rem !important;
  --padding-bottom: 1.25rem !important;
  --padding-start: 1.5rem;
  --padding-end: 1.5rem;
}

.button-solid {
  --box-shadow: none;
}

.plus-button {
  height: calc(1.25 * var(--space-mdlg)); //30px
  width: calc(1.25 * var(--space-mdlg)) !important; //30px
  font-size: calc(1.25 * var(--space-mdlg)); //30px
  color: #ffc773;
  background-color: #fff;
  border-radius: 50%;
  z-index: 50;

  &:hover {
    opacity: 0.85;
    cursor: pointer;
  }
}

.icon-button {
  color: var(--ion-color-primary);
}

.x-button {
  border-radius: 15px;
  height: calc(1.25 * var(--space-mdlg)); //30px
  width: calc(1.25 * var(--space-mdlg)); //30px
  background-color: var(--ion-color-light);
  font-size: 12px;

  &:hover {
    opacity: 0.6;
  }
}

.button-img {
  cursor: pointer;
  vertical-align: top;

  &:hover {
    opacity: 0.8;
  }

  &:active,
  &:focus {
    -ms-transform: translate(0px, 1px);
    -webkit-transform: translate(0px, 1px);
    transform: translate(0px, 1px);
  }
}

.text-button {
  color: var(--ion-color-primary);
  font-family: "Inter", sans-serif;
  //text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.1rem;
  font-weight: 500;
}

.completed {
  color: var(--ion-color-tertiary) !important;
}

// Layout

li {
  list-style: none;
}

.header-md::after {
  height: 0px; // Override Ionic header drop shadow (uses an image of height 5px in ::after)
}

.split-pane-md.split-pane-visible > .split-pane-side {
  max-width: 15%;
}

ion-modal {
  --border-radius: 7px !important;
  --min-height: 380px;
}

.modal-content {
  .x-button {
    float: right;
  }
  overflow-y: auto;
}

.modal-wrapper {
  min-height: 50px !important;
}

.panes {
  height: 100%;
  width: 100%;
  display: flex;

  .pane {
    height: 100%;
    margin: 0px;
    flex-grow: 1;
    border-right: var(--border);
  }
}

hr {
  background-color: var(--ion-color-light);
}

.checkIn {
  padding: 0.5rem 0.9rem;
  font-size: 0.9rem;
  border-radius: 6.25rem;
  font-weight: 500;
  background: #2b2930;
  color: #ffffff;
}

.flexBoxRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.justify-content-center {
  justify-content: center;
}

.routine-form-item-text {
  color: #79777c;
}

.routine-form-item {
  color: #413d45;
  font-size: 14px;
  font-weight: 500;
}

.masonry-grid {
  display: flex;
  gap: 2rem;

  ion-card {
    margin: 0;
    max-width: none;
  }
}

.masonry-grid-column {
  width: 100%;
}

// .masonry-grid-column-noPadd {
//   padding-left: 0px;
// }

.masonry-grid-column {
  ion-card {
    margin-bottom: 25px;
    /* match gutter */
  }
}

.ant-list-items {
  border-bottom: 1px solid #e8e8e8;
}

h4.ant-list-item-meta-title {
  margin-top: 0;
}

.fancy-divider {
  font-style: italic;
  font-size: 14px !important;
}

.nav-menu {
  background: #2b2930;
  height: 100vh;
  overflow: hidden;
  margin-bottom: 0;

  .active-navlink {
    font-weight: 500;

    li {
      background: #37343d;
      padding: 0.8rem 0 0.8rem 1rem;
      margin-right: 2rem;
      margin-left: -1rem;
      border-radius: 6.25rem;

      span {
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        gap: 1rem;

        .active {
          fill: #ff7e1d;
        }
      }
    }
  }

  .active {
    fill: #ffffff8a;
  }

  li {
    font-size: var(--space-unit);
    padding: 0.8rem 0 0.8rem 1rem;
    margin-right: 2rem;
    margin-left: -1rem;
    margin-bottom: 1rem;
    color: rgba(255, 255, 255, 0.54);

    span {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .anticon {
      margin-right: 6px;
    }
  }
}

.active {
  fill: #ffffff8a;
  z-index: 2;
}

.bubble-container {
  position: fixed;
  height: 40%;
  max-height: 280px;
  width: 100%;
  bottom: 0;
  overflow: hidden;

  @media screen and (min-width: 1600px) {
    max-height: none;
  }

  @media screen and (max-height: 799px) {
    display: none;
  }

  .inner-circle-one {
    height: 300%;
    width: 300%;
    background-color: #2d2b32;
    border-radius: 50%;
    position: absolute;
    left: -150%;

    .inner-circle-two {
      height: 100%;
      width: 100%;
      background-color: #2f2d34;
      border-radius: 50%;
      position: absolute;
      left: -5%;
      top: 8%;
      box-shadow: 0px 5px 10px 0px #1d1e21;

      .inner-circle-three {
        height: 75%;
        width: 75%;
        background-color: #312f36;
        border-radius: 50%;
        position: absolute;
        top: 10%;
        left: 15%;
        box-shadow: 0px 5px 10px 0px #1d1e21;
      }
    }
  }
}

// Forms

.goal-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 0.5rem;

  @media screen and (max-width: 600px) {
    padding-left: 1.5rem;
  }

  .goal-form {
    width: 400px;

    @media screen and (max-width: 600px) {
      width: 95%;
    }

    min-width: 200px;

    .form-buttons-wrapper {
      display: flex;

      .cancel-button {
        margin-left: auto;
        margin-right: 1rem;
        font-size: var(--font-sm);
        color: #413d45;
        background: #fafafa;
        padding: 0.8rem 1rem;
        border: none;
        border-radius: 100px;
        display: flex;
        align-items: center;
      }
    }
  }

  h3 {
    text-align: center;
    margin-bottom: var(--space-mdlg);
  }

  .ant-alert {
    width: 50%;
    margin: auto;
  }
}

//This is for Ionic component IonSelect, our "Goal Picker" on ActivityForm.tsx and EditActivityForm.tsx and the only place used in the app to date
ion-select {
  padding: 5px 0px;
}

//This is for Ionic component IonSelect, our "Goal Picker" on ActivityForm.tsx and EditActivityForm.tsx
.alert-radio-label {
  font-size: 14px !important;
}

//For Ionic component IonSelect, our "Goal Picker" on ActivityForm.tsx and EditActivityForm.tsx  This is used within elements using Ant Design styling.
.ant-input-mimic,
.ant-input-mimic-date,
.new-date-picker {
  width: 80%;
  border-radius: 100px !important;
  text-align: center;
  border: 1px solid;
  border-color: #d9d9d9;
  background: #fafafa;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  line-height: calc(1.5 * var(--space-sm)); //18px
  font-size: 14px;
  font-variant: tabular-nums;
  font-feature-settings: "tnum", "tunm";
}

.ant-input-affix-wrapper {
  line-height: 1.9;
}

.ant-input-mimic-date {
  width: 8rem !important;
  padding-right: 11px;
}

.new-date-picker {
  width: 8rem !important;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem !important;
}

.ant-input {
  color: #79777c !important;
  background: #fafafa;
  border-color: #e9e7e4;

  &::placeholder {
    color: #79777c;
  }
}

.ant-input:hover {
  border-color: #e9e7e4;
}

.ant-input:focus {
  border-color: #e9e7e4;
  box-shadow: 0 0 0 2px #e9e7e4;
}

.ant-tooltip-inner {
  background-color: var(--ion-color-dark);
  font-family: "Inter", sans-serif;
}

// Cards

ion-card {
  max-width: calc(4.6875 * var(--space-xxl)); //300px
}

.card-tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .ant-tag {
    font-size: 0.75rem;
    font-weight: 500;
    margin-right: 6px;
    margin-top: 8px;
    white-space: nowrap;
  }
}

.card-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.card-buttons {
  position: absolute;
  right: 1rem;
  z-index: 999;

  span {
    margin-left: 8px;
  }
}

.goal-card {
  background: #ffffff;
  border-top: 0.5rem solid #ffc773;
  border-radius: 0.5rem;
  min-width: calc(5.2 * var(--space-xxl)); //300px
  width: 100%;
}

.completed-goal-card {
  min-width: calc(5.2 * var(--space-xxl)); //300px
  width: 100%;
  border-top: 3px solid var(--ion-color-tertiary);
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #333;
}

.goal-card-link {
  :hover {
    color: orange;
  }
}

ion-card-header {
  //access text within card
  ion-card-title {
    font-weight: bold;
    font-family: "Inter", sans-serif;

    a {
      color: var(--ion-color-dark);

      &:hover {
        color: var(--ion-color-primary);
      }
    }
  }

  ion-card-subtitle {
    color: var(--ion-color-medium);
  }
}

.goal-card-descr {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px !important;
  color: #2b2930;
  letter-spacing: 0;
}

.card-links {
  display: flex;
  justify-content: space-between;
}

// Auth

.terms-optin {
  width: 110%;
  margin: 2rem 0 2rem -4%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
}

.logo-link {
  gap: 0.5rem;
}

//Check-In badge on SplitLayout.tsx

.check-in-inline-badge {
  display: flex;
  justify-content: flex-start;
}

.check-in-badge .ant-badge-count {
  margin-left: 7px;
  margin-top: 3px;
  background-color: var(--ion-color-primary);
}

// The following is from global.scss.

.main-area {
  margin-top: 2rem;
}

.main-content {
  margin: 0 4rem;
}

.form-container {
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;

  h1 {
    margin-bottom: 24px;
  }

  .ant-alert {
    margin-bottom: 24px;
  }
}

.form-submit-button {
  font-family: "Inter", sans-serif;
  letter-spacing: 0.1rem;
  font-weight: 500; //medium
  font-size: var(--font-sm);
  padding: 0.75rem 1rem;
  border: none;
  display: flex;
  align-items: center;
  background: #1877f2;
  color: #ffffff;
  border-radius: 8px;
}

.logomark {
  width: calc(1.25 * var(--space-lg)); //40px
  height: calc(1.25 * var(--space-lg)); //40px
}

.logotype {
  color: #2b2930;
  padding-left: 8px; // Refactor spacing in theme.scss (Courtney Schild)
}

ul {
  list-style: none;
}

.cards-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

// Helper Classes

.center {
  margin-left: auto;
  margin-right: auto;
}

.center-text {
  text-align: center;
}

.left-align-text {
  text-align: left;
}

.center-flex {
  display: flex;
  justify-content: center;
}

.bump-top {
  margin-top: 10px;
}

.bump-right {
  margin-right: 10px;
}

.bump-bottom {
  margin-bottom: 10px;
}

.bump-left {
  margin-left: 10px;
}

.popover-content.sc-ion-popover-md {
  .list-md {
    background: #413d45;
    padding: 0;
  }

  width: fit-content;
}

.item-native {
  background: #413d45 !important;

  .item-inner {
    padding-inline-end: 0 !important;
  }
}

.sc-ion-popover-md {
  background: transparent;
}

.logoDashboard {
  margin-left: -1rem;
}

.DashboardLeftLogo {
  margin: 3rem 0 3rem 0;
}

.no-border-right {
  border-right: 0;
}

.header {
  width: 100%;
  margin: 1rem 2rem 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-profile-header {
  width: 100%;
  margin: 1rem 2rem 2rem 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 760px) {
    margin: 1rem 2rem 2rem 1rem;
  }
}

.plus-sign {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
}

.plus-sign::before,
.plus-sign::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 2px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  border-radius: 2px;
}

.plus-sign::before {
  transform: translate(-50%, -50%) rotate(90deg);
}

//This line below is to fix the issue with the popover nFot showing up
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.IconWrapper {
  background: #f8ba121a;
  border-radius: 2rem;
  height: fit-content;
  padding: 0.8rem 0.8rem;
  display: flex;
  align-items: center;

  .active {
    fill: #ff7e1d;
  }
}

.ant-btn-primary {
  background: #0d6ef9;
  border: #0d6ef9;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #0d6ef9;
  opacity: 0.75;
}

.inspirationGoalTempCard {
  // padding: 0 1.5rem;
  margin: 0;
  width: 100%;
}

.template-card-header {
  padding: 0;
  text-align: left;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.goal-temp-card {
  border-top: 0.5rem solid #ffc773;
  padding: 1rem;
  background: #ffffff;
  border-radius: 0.5rem;
  max-width: none;

  .temp-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin: 0 1rem;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
    margin-top: 0.5rem;

    .img-container {
      height: auto;
      width: 100%;
    }
  }
}

.goal-temp-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.tab-buttons {
  margin-left: auto;
  display: flex;
  gap: 0.5rem;
  margin-bottom: -0.5rem;

  @media screen and (max-width: 500px) {
    max-width: 200px;
    margin-left: 0.5rem;
    overflow: auto;
  }

  .activeTab {
    background: #e9e7e4;
    color: #413d45;
  }

  .inactiveTab {
    background: transparent;
    color: #9f9f9d;
  }

  .activeTab,
  .inactiveTab {
    padding: 0.4rem 0.75rem;
    border-radius: 6rem;
    font-weight: 600;
  }
}

.routine-temp-card {
  border-left: 0.5rem solid #8e24aa;
}

.temp-card {
  padding: 1rem;
  background: #ffffff;
  border-radius: 0.5rem;
  max-width: none;

  .temp-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin: 0 1rem;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 1rem;
  }
}

.task-temp-card {
  border-left: 0.5rem solid #613cb0;
}

.routine-plus {
  color: #8e24aa;
}

.task-plus {
  color: #613cb0;
}

.closeSVG {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 0.5rem;
  border-radius: 100px;
  background: #e9e7e4;
  cursor: pointer;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin: 0 auto;
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  padding: 0 auto;
}

.not-found-small {
  height: 15vh;
  width: 100%;
}

.shorterButton {
  min-height: 24px;
  padding: 0.2rem 0;
  overflow: hidden;
}

.dropdown-up .ant-select-dropdown {
  transform: scaleY(-1);
}

.select-option {
  text-align: center;

  ::selection {
    background: #ffc773;
  }
}

.date-picker-dropdown {
  border-color: #d9d9d9 !important;
  background: #fafafa;
  box-shadow: none !important;
}

.ant-select-selector,
.date-picker-select {
  border-color: #d9d9d9 !important;
  background: #fafafa;
  box-shadow: none !important;
  border-radius: 1.5rem;
}

.date-picker-select {
  width: 8rem !important;
  text-align: center;
}

.fullWidth {
  width: 100%;
}

.plus-minus-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100rem !important;
  background: #1877f214 !important;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0.3rem 0.55rem;
  color: #0d6ef9 !important;
  border: none;

  &:hover {
    color: #0d6ef9 !important;
    background: #1877f214 !important;
    opacity: 0.75 !important;
  }
}

.routine-form-input {
  width: 4rem !important;
  padding: 0.25rem 0.75rem;
  text-align: center;
  border-radius: 0.5rem !important;
  border: 1px solid #e9e7e4 !important;
  box-shadow: none !important;

  .ant-btn {
    &:active,
    &:focus,
    &:hover,
    &:visited,
    &::after {
      border: 1px solid #e9e7e4 !important;
      border-color: #e9e7e4 !important;
      background: #fafafa !important;
      box-shadow: none !important;
    }
  }

  &:active,
  &:focus,
  &:hover,
  &:visited,
  &::after {
    border: 1px solid #e9e7e4 !important;
    border-color: #e9e7e4 !important;
    background: #fafafa !important;
    box-shadow: none !important;
  }
}

@media screen and (max-width: 768px) {
  .main-content {
    margin: 0 1rem;
  }

  .create-goal-button {
    padding: 0;
  }

  .goal-modal {
    --width: 90vw;
    max-height: 100vh;
  }

  
}

.step-title {
  font-size: 0.9rem;
  font-weight: 500;
  color: #413d45;
}

.step-description {
  font-size: 0.75rem;
  font-weight: 400;
  color: #79777c;
}

.orange-radio {
  color: #ff7e1d;
  background: #ff7e1d1a;
  border-radius: 6.25rem;
  padding: 0.4rem 1.2rem;

  .ant-radio-inner {
    border-color: #ff7e1d;
  }

  .ant-radio-inner::after {
    background-color: #ff7e1d;
  }
}

.grey-radio {
  color: #d9d7d5;
  border-color: transparent !important;

  .ant-radio-inner {
    border-color: #d9d7d5 !important;
  }
}

// .green-radio {
//   color: green;
// }

.white-bg {
  background: #fff;
}

.orange-button {
  --ion-color-primary: #ff7e1d;
}

.check-in-checkbox {
  position: absolute;
  left: -2rem;
  height: 1rem !important;
  opacity: 1 !important;
  width: 1rem !important;
}

.delete-button {
  background: #e75d4a;
  padding: 0.15rem 0.6rem;
  border-radius: 6.25rem;
}

.ant-popover-inner-content {
  font-family: "Inter", sans-serif;
}

.ant-btn-sm {
  border-radius: 4px;
  padding: 0 1rem;
  height: 32px;
}

.collective-routines {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
  row-gap: 3rem;
  margin-top: 1.5rem;

  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (min-width: 641px) and (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  // @media screen and (min-width:1025px) and (max-width:1280px){
  //   grid-template-columns: repeat(3,1fr);
  // }
}

.logo-title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.goal-text-detail-form {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.2rem;
  @media screen and (max-width: 450px) {
    max-width: 125px;
  }
}

.goal-text {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.2rem;

  // @media screen and (max-width: 1500px) {
  //   max-width: 150px;
  // }

  @media screen and (max-width: 450px) {
    max-width: 150px;
  }
}

.goal-text-select {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.7rem;
}

// .sc-ion-alert-md-h{
//   --min-width: auto;
// }
.alert-radio-label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  line-height: 1.4;
  white-space: unset !important;
}

.alert-radio-group.sc-ion-alert-md,
.alert-checkbox-group.sc-ion-alert-md {
  border-top: 0px !important;
  border-bottom: 0px !important;
}

.alert-head.sc-ion-alert-md {
  padding-inline-start: 0px !important;
  padding-top: 0px !important;
  --min-width: max-content;
}

.alert-button-group.sc-ion-alert-md {
  border-top: 1px solid #d9d9d9;
  padding-top: 0px;
  margin-top: 1rem;
}

.no-goal-selected {
  border: 1px solid #ff7d6b;
  border-radius: 0.5rem;
  color: #ff7d6b !important;
}

.error-container {
  position: absolute;
  left: 0;
  right: 0;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.min-w-max {
  min-width: max-content;
}

.min-w-min {
  min-width: min-content;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

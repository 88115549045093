.check-ins-list {
  max-width: 1000px;
}

.check-ins-row-icons {
  align-self: center;
  margin-left: 2px;
}

.filledCalender {
  background: #F8BA121A;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #FF7E1D;
  min-width: 48px;
  min-height: 48px;
}

.title-check-ins {
  color: #2B2930;
  font-size: 0.9rem;
  font-weight: 600;
  //font-family: 'inter';
  margin: 0.2rem 0;
}

.checkInTag {
  padding: 0.2rem 0.8rem;
  border-radius: 6.25rem;
  font-weight: 500;
  font-size: 0.75rem;
}

.inProgress {
  background: #FECE0033;
  color: #C0931D;
}

.nextButton {
  padding: 0 1rem;
  background-color: #0D6EF9;
  display: flex;
  min-height: 2.5rem;
  width: fit-content;
  align-items: center;
  color: white;
  border-radius: 2rem;
  cursor: pointer;
}

.title-review {
  font-size: 1.4rem;
  font-weight: 500;
  color: #413D45;
}

.subTitle-review {
  font-size: 0.75rem;
  font-weight: 400;
  color: #79777C;
}

.step-one,
.step-two,
.step-three,
.steps-complete {
  margin-top: 2.5rem;
}

.form-title {
  font-size: 0.9rem;
  color: #4B4A4D;
  display: block;
  text-align: left !important;
  margin: 0.5rem 0;
}

.learned-textarea {
  max-height: 10rem;
  height: 10rem !important;
  resize: none;
}

.enjoyed-textarea {
  max-height: 7rem;
  height: 7rem !important;
  resize: none;
}

.future-changes-textarea {
  max-height: 5rem;
  height: 5rem !important;
  resize: none;
}
.ant-result-icon{
  margin-bottom: 0 !important;
}
.ant-result-title {
  color: #2B2930 !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}
.ant-result-subtitle{
  color: #2B2930 !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
}
.get-inspired{
  color:#2B2930;
  border: 2px #413D45 solid;
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
}
// .list-item{
//   display: flex;
//   @media screen and (max-width: 400px) {
//     flex-direction: column;
//     align-items: normal;
//     width: 80%;
//     margin-left: 10%;
//   }
// }
.list-container{
  @media screen and (max-width: 500px) {
    width: 99%;
    margin-left: auto;
    margin-right: auto;
  }
}
 .ant-list-item{
   @media screen and (max-width: 400px) {
    flex-direction: column;
    align-items: normal;
    width: 95%;
    flex-wrap: nowrap;
    gap: 1rem;
    // margin-left: 0.5rem;
   }
 }
.no-left-padding {
    padding-inline-start: 0;
}

.card-tags {
    .ant-tag {
        color: #413D45 !important;
        background: #FAFAFA !important;
        padding: 0.25rem 0.8rem;
        border-radius: 6.25rem;
        border: none;
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}

.overflow-container {
  overflow-y: scroll;
}

.desc-20 {
  font-size: 24px;
  font-weight: 400;
  color: #79777C;
  width: 65%;
  text-align: center;
  margin: 5rem auto 0 auto;
}

.container {
  position: relative;
  background-image: linear-gradient(#E8E5FC,
      #FFECC7);
  display: flex;
  justify-content: center;
}

.section-title {
  font-size: 2rem;
  font-weight: 800;
  color: #413D45;
}

.section-desc {
  font-size: 1rem;
  font-weight: 400;
  color: #79777C;
  max-width: 40rem;
}

.w-30 {
  width: 30%;
  max-width: 24rem;
}

.row-reverse {
  flex-direction: row-reverse;
}

.navbar {
  position: absolute;
  top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  height: var(--space-xxl); // Refactor spacing in theme.scss (Courtney Schild)
  padding: 0 2.5rem;
  z-index: 2;
}

.sign-in-link {
  margin-left: auto;
  color: white;
  font-weight: 400;

  &:hover {
    color: white;
    font-weight: 500;
  }

  &:active {
    color: var(--ion-color-primary-shade);
    font-weight: 500;
  }
}

.fw-800 {
  font-weight: 800;
}

.cta-container {
  position: absolute;
  top: 20vh;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.cta {
  z-index: 1;
}

.tagline {
  color: #000000;
  font-family: "Inter", sans-serif; // Refactor all font-related styling in theme.scss (Courtney Schild)
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
}

.description {
  margin-top: 0;
  color: #000000;
  font-family: "Inter", sans-serif; // Refactor all font-related styling in theme.scss (Courtney Schild)
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
}

.cta-button {
  margin: 0 8px; // Refactor spacing in theme.scss (Courtney Schild)
  border: 2px solid #4f4df2;
  border-radius: 16px;
  color: #4f4df2;
  display: flex;
  width: fit-content;
  gap: 4px;
  padding: 0 !important;
}

.hero-container {
  display: flex;
  flex-direction: column;
}

.sun {
  .sun-img {
    width: 100vw;
  }
}

// After removing height: 0 and padding-bottom: 100%, this is another way to achieve the same effect as .sun above:
// .sun:after {
//   content: "";
//   display: block;
//   padding-bottom: 100%;
// }

.social-media-icons {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.social-media-icon {
  color: white;
  font-size: 16px;
  padding: 8px;

  &:hover {
    font-size: 32px;
    color: white;
  }

  &:active {
    font-size: 32px;
    color: var(--ion-color-secondary-shade);
  }
}


/* Media Queries */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .pt-10 {
    padding-top: 15rem !important;
  }

  .mobile-col {
    flex-direction: column !important;
  }

  .sun {
    margin-top: 5rem;

    .sun-img {
      width: 100vw;
      max-height: 200px;
    }
  }

  .items-container {
    flex-direction: column;
    gap: 2rem !important;

    .w-30 {
      width: 90%;
    }
  }

  .clarity-container {
    flex-direction: column;
    gap: 2rem !important;

    .w-30 {
      width: 90%;
    }
  }

  .reflection-container {
    flex-direction: column;
    gap: 2rem !important;

    .w-30 {
      width: 90%;
    }
  }

  .testimonial-container {
    overflow-x: scroll;
    width: 100vw;
    justify-content: flex-start !important;
  }

  .inspiration-container {
    overflow-x: scroll;
    justify-content: flex-start !important;
    width: 100vw;
  }

  .ins-img-con {
    min-width: 150px;
  }

  .navbar {
    padding: 0 16px;
  }

  .cta-footer {
    top: 0vh !important;
  }

  .tagline {
    font-size: 24px;
    line-height: 28.8px;
    padding-bottom: 12px;
  }

  .description {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 12px;
  }

  .tagline-footer {
    font-size: 1.2rem !important;
    line-height: 26px;
    padding-bottom: 12px;
  }

  .description-footer {
    font-size: 0.9rem !important;
    line-height: 16px;
    padding-bottom: 12px;
    width: 70% !important;
  }

  .section-title {
    font-size: 1.2rem !important;
    text-align: center;
  }

  .section-desc {
    font-size: 0.9rem !important;
    text-align: center;
    padding: 0 0.5rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tagline {
    font-size: 36px;
    line-height: 43.2px;
    padding-bottom: 16px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
  }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .tagline {
    font-size: 48px;
    line-height: 57.6px;
    padding-bottom: 20px;
  }

  .description {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .tagline {
    font-size: 60px;
    line-height: 72px;
    padding-bottom: 24px;
  }

  .description {
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 24px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .tagline {
    font-size: 60px;
    line-height: 72px;
    padding-bottom: 24px;
  }

  .description {
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 24px;
  }
}

.items-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.clarity-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin: 6rem 0;
}

.reflection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin: 6rem 0;
}

.testimonial-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin: 4rem 0 2rem 0;
}


.mt-10r {
  margin-top: 5rem;
}

.h-sm {
  height: 25vh;
}

.inspiration-container {
  display: grid;
  grid-template-columns: repeat(5, 0.95fr);
  grid-gap: 1rem;
  justify-content: center;
  padding: 0 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-gradient {
  background-image: linear-gradient(white, #9383d2);
}

.bg-gradient1 {
  background-image: linear-gradient(#9383d2, #53458f);
}

.bg-gradient2 {
  background-image: linear-gradient(#53458f, #1b1433);
  position: relative;
}

.color-white {
  color: white !important;
}

.testimonial-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 300px));
  gap: 1rem;
  justify-content: center;
}

.testimonial {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  border-radius: 1rem;
  background-color: #FFFFFF12;
  border: 2px solid #FFFFFF21;
  color: white;

  p {
    font-size: 0.8rem;
    font-weight: 400;
  }

  .author {
    margin-top: auto;
    font-size: 1rem;
    font-weight: 700;
  }
}

.w-50 {
  width: 50%;
}

.tagline-footer {
  font-weight: 800;
  font-size: 2rem;
  color: #E8E5FC;
}

.description-footer {
  font-weight: 400;
  font-size: 1rem;
  color: #E8E5FC;
  margin: 1rem auto;
  width: 60%;
}

.pt-5 {
  padding-top: 3rem;
}

.pt-10 {
  padding-top: 10rem;
}

.footer-container {
  position: relative;
  bottom: 0;
  background: #1C1534;
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
  padding: 0 2.5rem;
  z-index: 2;
}

.footer {
  display: flex;
  align-items: center;
  align-self: center;
  height: var(--space-xxl); // Refactor spacing in theme.scss (Courtney Schild)
  width: 100%;
}

.footer-text {
  color: #FFFFFF70;
  font-size: 1rem;
  font-weight: 400;
}

.arrow-button-container {
  background: #694DF2;
  padding: 1rem 2rem;
  border-radius: 10rem;
  cursor: pointer;
}

.menu {
  position: absolute;
  display: none;
  flex-direction: column;
  gap: 1rem;
  background: white;
  width: 100vw;
  height: 100vh;
  padding: 5rem 2rem 0 2rem;
  z-index: 9;
}

.z-1000 {
  z-index: 1000;
}

.show {
  display: flex;
  animation-name: rightToLeft;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: forwards;
}

.hide {
  animation-name: leftToRight;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: forwards;
}

@keyframes rightToLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes leftToRight {
  0% {
    display: flex;
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
    display: none;
  }

}
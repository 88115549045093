.userDetailContainer{
  display: flex;
  align-items: center;
  text-wrap: wrap;
  .avatar{
    min-width: 2rem;
  }
  .picture-avatar{
    border-radius: 50%;
    object-fit: cover;
    width: 60px;
    height: 60px;
    background: transparent;
    .ant-avatar-string{
      width: 100%;
    }
    .avatar-img{
      height: 60px;
      width: 60px;
      border-radius: 50%;
    }
  }
  .userDetail{
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    font-size: 0.9rem;
    font-weight: 400;
    .userName{
      color:#413D45;
    }
    .userEmail{
      color:#79777C;
    }
  }
}

.profile-container{
  display: flex;
  align-items: center;
  gap:1rem;
  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
}


.reset-button{
  background: #FF7E1D;
  border-radius: 8rem;
  color: #fff;
  font-weight: 500;
  width: max-content;
  padding: 0.8rem 1rem;
}
.empty{
  text-decoration: none;
  color: #fff;
  white-space: nowrap;
  &:hover{
    text-decoration: none;
    color: #fff !important;
  }
}
.flexBox{
  display: flex;
  align-items: center;
}
.marginLeft{
  margin-top: -0.2rem;
  margin-left: 0.5rem;
}
.ionic-button{
  --border-radius: 8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-size: 12px;
  background:#FAFAFA;
  border-radius: 8rem;
  white-space: nowrap;
  padding: 0.9rem 1rem;
  &:hover{
    background: #e6e6e6;
  }
}
.buttons-container{
  display: flex;
  gap: 1rem;
  @media screen and (max-width: 1064px) and (min-width: 991px) {
    flex-direction: column;
  }
  @media screen and (max-width: 818px) and (min-width: 768px) {
    flex-direction: column;
  }

}
.team-info-container{
  display: flex;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.remove-link-account-button{
  margin-left: auto;
  z-index: 9999;
  min-height: 24px;
  @media screen and (max-width: 500px) {
    margin-left: 0;
  }
}
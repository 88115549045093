@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap);
.header-content-wrapper {
  display: flex;
  justify-content: space-between; }

.overflow-container {
  overflow-y: scroll; }

.desc-20 {
  font-size: 24px;
  font-weight: 400;
  color: #79777C;
  width: 65%;
  text-align: center;
  margin: 5rem auto 0 auto; }

.container {
  position: relative;
  background-image: linear-gradient(#E8E5FC, #FFECC7);
  display: flex;
  justify-content: center; }

.section-title {
  font-size: 2rem;
  font-weight: 800;
  color: #413D45; }

.section-desc {
  font-size: 1rem;
  font-weight: 400;
  color: #79777C;
  max-width: 40rem; }

.w-30 {
  width: 30%;
  max-width: 24rem; }

.row-reverse {
  flex-direction: row-reverse; }

.navbar {
  position: absolute;
  top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  height: var(--space-xxl);
  padding: 0 2.5rem;
  z-index: 2; }

.sign-in-link {
  margin-left: auto;
  color: white;
  font-weight: 400; }
  .sign-in-link:hover {
    color: white;
    font-weight: 500; }
  .sign-in-link:active {
    color: var(--ion-color-primary-shade);
    font-weight: 500; }

.fw-800 {
  font-weight: 800; }

.cta-container {
  position: absolute;
  top: 20vh;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center; }

.cta {
  z-index: 1; }

.tagline {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  margin-bottom: 0;
  padding-bottom: 0.5rem; }

.description {
  margin-top: 0;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap; }

.cta-button {
  margin: 0 8px;
  border: 2px solid #4f4df2;
  border-radius: 16px;
  color: #4f4df2;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 4px;
  gap: 4px;
  padding: 0 !important; }

.hero-container {
  display: flex;
  flex-direction: column; }

.sun .sun-img {
  width: 100vw; }

.social-media-icons {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; }

.social-media-icon {
  color: white;
  font-size: 16px;
  padding: 8px; }
  .social-media-icon:hover {
    font-size: 32px;
    color: white; }
  .social-media-icon:active {
    font-size: 32px;
    color: var(--ion-color-secondary-shade); }

/* Media Queries */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .pt-10 {
    padding-top: 15rem !important; }
  .mobile-col {
    flex-direction: column !important; }
  .sun {
    margin-top: 5rem; }
    .sun .sun-img {
      width: 100vw;
      max-height: 200px; }
  .items-container {
    flex-direction: column;
    grid-gap: 2rem !important;
    gap: 2rem !important; }
    .items-container .w-30 {
      width: 90%; }
  .clarity-container {
    flex-direction: column;
    grid-gap: 2rem !important;
    gap: 2rem !important; }
    .clarity-container .w-30 {
      width: 90%; }
  .reflection-container {
    flex-direction: column;
    grid-gap: 2rem !important;
    gap: 2rem !important; }
    .reflection-container .w-30 {
      width: 90%; }
  .testimonial-container {
    overflow-x: scroll;
    width: 100vw;
    justify-content: flex-start !important; }
  .inspiration-container {
    overflow-x: scroll;
    justify-content: flex-start !important;
    width: 100vw; }
  .ins-img-con {
    min-width: 150px; }
  .navbar {
    padding: 0 16px; }
  .cta-footer {
    top: 0vh !important; }
  .tagline {
    font-size: 24px;
    line-height: 28.8px;
    padding-bottom: 12px; }
  .description {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 12px; }
  .tagline-footer {
    font-size: 1.2rem !important;
    line-height: 26px;
    padding-bottom: 12px; }
  .description-footer {
    font-size: 0.9rem !important;
    line-height: 16px;
    padding-bottom: 12px;
    width: 70% !important; }
  .section-title {
    font-size: 1.2rem !important;
    text-align: center; }
  .section-desc {
    font-size: 0.9rem !important;
    text-align: center;
    padding: 0 0.5rem; } }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tagline {
    font-size: 36px;
    line-height: 43.2px;
    padding-bottom: 16px; }
  .description {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px; } }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .tagline {
    font-size: 48px;
    line-height: 57.6px;
    padding-bottom: 20px; }
  .description {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 20px; } }

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .tagline {
    font-size: 60px;
    line-height: 72px;
    padding-bottom: 24px; }
  .description {
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 24px; } }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .tagline {
    font-size: 60px;
    line-height: 72px;
    padding-bottom: 24px; }
  .description {
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 24px; } }

.items-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5rem;
  gap: 5rem; }

.clarity-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5rem;
  gap: 5rem;
  margin: 6rem 0; }

.reflection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5rem;
  gap: 5rem;
  margin: 6rem 0; }

.testimonial-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5rem;
  gap: 5rem;
  margin: 4rem 0 2rem 0; }

.mt-10r {
  margin-top: 5rem; }

.h-sm {
  height: 25vh; }

.inspiration-container {
  display: grid;
  grid-template-columns: repeat(5, 0.95fr);
  grid-gap: 1rem;
  justify-content: center;
  padding: 0 1rem; }

.cursor-pointer {
  cursor: pointer; }

.bg-gradient {
  background-image: linear-gradient(white, #9383d2); }

.bg-gradient1 {
  background-image: linear-gradient(#9383d2, #53458f); }

.bg-gradient2 {
  background-image: linear-gradient(#53458f, #1b1433);
  position: relative; }

.color-white {
  color: white !important; }

.testimonial-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 300px));
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center; }

.testimonial {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 2rem;
  border-radius: 1rem;
  background-color: #FFFFFF12;
  border: 2px solid #FFFFFF21;
  color: white; }
  .testimonial p {
    font-size: 0.8rem;
    font-weight: 400; }
  .testimonial .author {
    margin-top: auto;
    font-size: 1rem;
    font-weight: 700; }

.w-50 {
  width: 50%; }

.tagline-footer {
  font-weight: 800;
  font-size: 2rem;
  color: #E8E5FC; }

.description-footer {
  font-weight: 400;
  font-size: 1rem;
  color: #E8E5FC;
  margin: 1rem auto;
  width: 60%; }

.pt-5 {
  padding-top: 3rem; }

.pt-10 {
  padding-top: 10rem; }

.footer-container {
  position: relative;
  bottom: 0;
  background: #1C1534;
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
  padding: 0 2.5rem;
  z-index: 2; }

.footer {
  display: flex;
  align-items: center;
  align-self: center;
  height: var(--space-xxl);
  width: 100%; }

.footer-text {
  color: #FFFFFF70;
  font-size: 1rem;
  font-weight: 400; }

.arrow-button-container {
  background: #694DF2;
  padding: 1rem 2rem;
  border-radius: 10rem;
  cursor: pointer; }

.menu {
  position: absolute;
  display: none;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  background: white;
  width: 100vw;
  height: 100vh;
  padding: 5rem 2rem 0 2rem;
  z-index: 9; }

.z-1000 {
  z-index: 1000; }

.show {
  display: flex;
  animation-name: rightToLeft;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: forwards; }

.hide {
  animation-name: leftToRight;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: forwards; }

@keyframes rightToLeft {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0%); } }

@keyframes leftToRight {
  0% {
    display: flex;
    transform: translateX(0%); }
  100% {
    transform: translateX(100%);
    display: none; } }

.auth-options {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 12px auto; }
  .auth-options a {
    width: 100%;
    color: #667085; }
    .auth-options a span {
      color: #FF7E1D; }

.m-y-auto {
  margin-top: auto;
  margin-bottom: auto; }

.m-x-auto {
  margin-left: auto;
  margin-right: auto; }

.form-item {
  text-align: left;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem; }

.sign-in-header {
  margin-bottom: 12px !important;
  color: black;
  font-weight: 600;
  font-size: 2rem; }

.sign-in-subheader {
  margin-bottom: 12px !important;
  color: #4B4A4D;
  font-weight: 400;
  font-size: 1rem; }

.label-sign-in {
  color: #344054;
  font-size: 0.75rem;
  font-weight: 500; }

.login-form {
  width: 100%;
  max-width: 20rem;
  margin: 0 auto; }

.input-sign-in {
  border-radius: 8px; }

.sign-in-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.mb-0 {
  margin-bottom: 0 !important; }

.google-button {
  background: #fff;
  border: 1px solid #D0D5DD;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  border-radius: 8px;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%; }

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.no-border {
  border: none; }

.border {
  border: 1px solid #D0D5DD; }

.padding-around {
  padding: 0.5rem 1rem 0.5rem 0rem; }

.actions-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; }

.occurence-container {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem; }

.counter-container {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem; }

label {
  cursor: pointer;
  position: relative;
  /* Needed for proper positioning */ }

input[type="checkbox"] {
  opacity: 0;
  /* Hide the actual checkbox */
  width: 0;
  height: 0; }

.task-round, .routine-round {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  /* Ensures a circular shape */
  border: 1px solid #613CB0;
  display: inline-block;
  vertical-align: middle;
  /* Aligns checkbox with label text */
  position: relative; }

input[type="checkbox"]:checked + .task-round {
  background-color: #613CB0;
  border-color: #613CB0; }

input[type="checkbox"]:checked + .routine-round {
  background-color: #8E24AA;
  border-color: #8E24AA; }

/* Optional: Style the checkmark (using pseudo-element) */
.task-round:after, .routine-round:after {
  content: "";
  display: none;
  /* Initially hide the checkmark */
  position: absolute;
  top: 45%;
  left: 50%;
  width: 12px;
  height: 6px;
  border: solid white;
  border-width: 0 0 2px 2px;
  transform: translate(-50%, -50%) rotate(-45deg); }

input[type="checkbox"]:checked + .task-round:after,
input[type="checkbox"]:checked + .routine-round:after {
  display: block;
  /* Show the checkmark when checked */ }

.activity-routine {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-left: 8px solid #8e24aa;
  border-radius: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  box-shadow: 0px 1px 2px 0px #1018280d; }

.activity-task {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-left: 8px solid #613cb0;
  border-radius: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  box-shadow: 0px 1px 2px 0px #1018280d; }

.activity-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 296px;
  margin: 8px 0;
  padding: 0.8rem 1rem 0 1rem; }

.small-bard-icon {
  width: 16px;
  height: 16px;
  color: white; }

.activity-row-2 {
  display: flex;
  flex-wrap: nowrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  padding: 0.8rem 1rem 0 1rem; }
  @media screen and (max-width: 768px) {
    .activity-row-2 {
      margin-left: 0; } }
  .activity-row-2 .activityLeft {
    background: #1c1b1f0a;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 0.5rem;
    color: #3c4043; }
    .activity-row-2 .activityLeft .gapS {
      grid-gap: 0.5rem;
      gap: 0.5rem; }
    .activity-row-2 .activityLeft .custom-progress .ant-progress-inner {
      background-color: blue; }
    .activity-row-2 .activityLeft .activityPadding {
      padding: 0 0.8rem;
      display: flex;
      align-items: center;
      grid-gap: 0.2rem;
      gap: 0.2rem;
      color: #3c4043; }
    .activity-row-2 .activityLeft:nth-child(3) {
      margin-left: auto;
      margin-right: 1rem;
      background: transparent; }
    .activity-row-2 .activityLeft .ant-btn-group {
      display: flex;
      border-radius: 50px;
      overflow: hidden;
      width: 4rem;
      margin: 0; }
      .activity-row-2 .activityLeft .ant-btn-group .divideLine {
        border-left: 1px solid #8e24aa; }
      .activity-row-2 .activityLeft .ant-btn-group .ant-btn {
        background: #f1e5f4;
        flex: 1 1;
        border-radius: 0;
        border: none;
        color: #8e24aa; }
        .activity-row-2 .activityLeft .ant-btn-group .ant-btn:hover {
          background: #f1e5f4;
          color: #8e24aa; }
        .activity-row-2 .activityLeft .ant-btn-group .ant-btn:last-child {
          border-right: none; }

.activeFlag {
  fill: #4b4a4d; }

.activity-row-left {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem; }

.activity-row-right {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem; }

.is-priority {
  background: #3c40430a;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  padding: 0.5rem; }

.menu-button-activity {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border-radius: 10rem;
  --ion-color-primary: #3c40430a;
  color: #2b2930;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.task-title {
  overflow: hidden;
  max-height: 21px;
  color: #2b2930;
  font-weight: 600; }

.task-complete-title {
  overflow: hidden;
  max-height: 21px;
  color: #2b2930;
  font-weight: 600;
  text-decoration: line-through; }

.routine-title {
  overflow: hidden;
  max-height: 21px;
  cursor: pointer; }
  .routine-title:hover {
    color: var(--ion-color-primary); }

.flag-box {
  margin: 0 1rem; }

.task-complete {
  text-decoration: line-through; }

.routine-complete {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  min-width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10.2px;
  color: white; }

.extra-routine {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  min-width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
  background-color: #8e24aa;
  border: 0; }
  .extra-routine p {
    margin: 0; }

.task-check-btn {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  min-width: 28px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10.2px;
  color: white; }

.activity-row-goal {
  overflow: hidden;
  font-size: 12px;
  max-height: 18px; }

.date {
  color: #3c4043;
  margin: 0 16px;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem; }

.ant-btn-group {
  white-space: nowrap;
  margin: 0 16px; }

.activity-row-icons {
  white-space: nowrap; }

.activity-row-icon {
  padding: 4px; }

.over-due-date {
  color: #ff7d6b; }

@media only screen and (max-width: 435px) {
  /* For mobile phones (iphone6/7/8 plus): */
  [class*="date"] {
    margin: 0 6px; }
  [class*="activity-row-left"] {
    max-width: 220px; } }

@media only screen and (max-width: 404px) {
  /* For mobile phones (iphone6/7/8): */
  [class*="activity-row-left"] {
    max-width: 200px; } }

@media only screen and (max-width: 375px) {
  /* For mobile phones (iphone6/7/8): */
  [class*="activity-row-left"] {
    max-width: 200px; } }

.popover {
  margin-left: -4rem;
  background: transparent; }

.edit {
  display: flex;
  margin-left: -1rem;
  margin-right: auto;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  color: white; }

.delete {
  display: flex;
  margin-left: -0.9rem;
  margin-right: auto;
  color: #ff7d6b;
  grid-gap: 0.8rem;
  gap: 0.8rem; }
  .delete p {
    margin: 0 0 0 0.5rem; }

.button-native:hover {
  color: black; }

.activity-routine:hover {
  cursor: pointer; }
  .activity-routine:hover .activity-row-titles .task-title {
    color: #ff7e1d; }
  .activity-routine:hover .activity-row-titles .task-complete-title {
    color: #ff7e1d; }

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2.6rem;
  grid-gap: 1rem;
  gap: 1rem; }

.content-container {
  margin: 0 1rem; }

.content-container-actions {
  margin: 0 2rem; }
  @media screen and (max-width: 768px) {
    .content-container-actions {
      margin: 0rem; } }

.dashboard-row {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem; }

.SiteHeaderSM {
  font-size: 0.9rem;
  font-weight: 600;
  align-items: center; }

.dashboard-column {
  width: 48%;
  padding: 0.3em;
  margin-top: 1em; }
  @media screen and (max-width: 1200px) {
    .dashboard-column {
      width: 100%; } }

@media screen and (max-width: 1200px) {
  .dashboard-row {
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem; } }

@media screen and (max-width: 768px) {
  .more-padding {
    padding: 0 !important; } }

@media only screen and (max-width: 414px) {
  /* For mobile phones (iphone6/7/8 plus): */
  [class*="width-control"] {
    max-width: 18rem;
    overflow-x: scroll;
    justify-content: space-between !important; } }

@media only screen and (max-width: 375px) {
  /* For mobile phones (iphone6/7/8): */
  [class*="width-control"] {
    max-width: 15.5rem;
    justify-content: space-between !important; } }

.add-button {
  color: white;
  background: black;
  --ion-color-primary: transparent; }

.buttonsContainer {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  position: relative; }
  .buttonsContainer .create-button {
    padding: 0.5rem;
    background-color: #0d6ef9;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    color: white;
    border-radius: 8rem;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
    transform: rotateZ(45deg);
    transition: all 0.3s; }
    .buttonsContainer .create-button .button-space {
      margin-left: 0.5rem; }
  .buttonsContainer .cross {
    transform: rotateZ(0deg);
    background: #ff7d6b;
    transition: all 0.3s; }
  .buttonsContainer .buttons {
    position: absolute;
    top: 2.5rem;
    left: -5.6rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 0.5rem 1rem 0.5rem;
    border-radius: 1rem; }
    .buttonsContainer .buttons .custom-button {
      --background: #0d6ef9;
      --color: #fff; }
    .buttonsContainer .buttons .plus-sign {
      margin-right: auto; }

.in-view {
  animation: moveDown 0.5s;
  margin-left: auto; }

@keyframes moveDown {
  from {
    transform: translateY(-1rem); }
  to {
    transform: translateY(0); } }

.width-control {
  justify-content: flex-end; }
  .width-control span {
    font-size: 0.8rem; }
    .width-control span .segment-button-checked {
      border-radius: 8rem;
      background: #e9e7e4;
      color: #413d45;
      border: none !important;
      --indicator-color: none !important; }
    .width-control span .in-segment {
      border-radius: 8rem;
      --background-focused: transparent !important;
      --background-hover: transparent !important; }

.more-padding {
  padding: 0 4rem; }

.flexBox {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem; }

.zIndex {
  z-index: 50; }

.segment-activated {
  padding: 0.5rem;
  border-radius: 8rem;
  background: transparent !important; }

.capitalize {
  text-transform: capitalize; }

.overflow-y-scroll {
  overflow-y: auto; }

ion-segment-button.segment-button-checked {
  --background: none !important;
  /* Remove default background */ }

.segment-button-activated {
  --background: none !important;
  /* Remove default background */ }

.check-ins-list {
  max-width: 1000px; }

.check-ins-row-icons {
  align-self: center;
  margin-left: 2px; }

.filledCalender {
  background: #F8BA121A;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #FF7E1D;
  min-width: 48px;
  min-height: 48px; }

.title-check-ins {
  color: #2B2930;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0.2rem 0; }

.checkInTag {
  padding: 0.2rem 0.8rem;
  border-radius: 6.25rem;
  font-weight: 500;
  font-size: 0.75rem; }

.inProgress {
  background: #FECE0033;
  color: #C0931D; }

.nextButton {
  padding: 0 1rem;
  background-color: #0D6EF9;
  display: flex;
  min-height: 2.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  color: white;
  border-radius: 2rem;
  cursor: pointer; }

.title-review {
  font-size: 1.4rem;
  font-weight: 500;
  color: #413D45; }

.subTitle-review {
  font-size: 0.75rem;
  font-weight: 400;
  color: #79777C; }

.step-one,
.step-two,
.step-three,
.steps-complete {
  margin-top: 2.5rem; }

.form-title {
  font-size: 0.9rem;
  color: #4B4A4D;
  display: block;
  text-align: left !important;
  margin: 0.5rem 0; }

.learned-textarea {
  max-height: 10rem;
  height: 10rem !important;
  resize: none; }

.enjoyed-textarea {
  max-height: 7rem;
  height: 7rem !important;
  resize: none; }

.future-changes-textarea {
  max-height: 5rem;
  height: 5rem !important;
  resize: none; }

.ant-result-icon {
  margin-bottom: 0 !important; }

.ant-result-title {
  color: #2B2930 !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important; }

.ant-result-subtitle {
  color: #2B2930 !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important; }

.get-inspired {
  color: #2B2930;
  border: 2px #413D45 solid;
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500; }

@media screen and (max-width: 500px) {
  .list-container {
    width: 99%;
    margin-left: auto;
    margin-right: auto; } }

@media screen and (max-width: 400px) {
  .ant-list-item {
    flex-direction: column;
    align-items: normal;
    width: 95%;
    flex-wrap: nowrap;
    grid-gap: 1rem;
    gap: 1rem; } }

.complete-goal-review-card {
  border-top: none !important; }
  .complete-goal-review-card .ant-card-head {
    background: linear-gradient(90deg, #efb39d 0%, #ffde6e 100%) !important;
    opacity: 0.7; }

.review-card-container {
  background: #FAFAFA;
  box-shadow: 0px 1px 2px #1018280D;
  padding: 1.5rem;
  border-radius: 0.5rem; }
  .review-card-container .review-goal-card-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #2B2930;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem; }

.activities-container {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: 1rem; }

.check-in-results {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem; }
  .check-in-results .ant-collapse {
    width: 100%;
    max-width: 800px;
    min-width: 400px;
    margin: auto; }
  .check-in-results .check-in-result {
    background: #FAFAFA;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem; }
    .check-in-results .check-in-result .check-in-title {
      font-size: 1rem;
      font-weight: 600;
      color: #413D45; }
    .check-in-results .check-in-result .check-in-description {
      font-size: 1rem;
      font-weight: 400;
      color: #79777C;
      white-space: pre-wrap; }

.steps-complete {
  max-width: 800px;
  min-width: 400px;
  margin: 2.5rem auto auto auto; }

.check-in-steps {
  margin: 2rem 0; }

.check-in-card-wrapper {
  margin: 2rem auto 0 auto;
  max-width: 850px;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem; }

.check-in-divider {
  margin: 2.5rem 0; }

.check-in-next-btn {
  margin: 1rem; }
  .check-in-next-btn svg {
    margin-right: 8px; }

.ci-step-three-form {
  width: 500px;
  margin: 2rem auto 0 auto; }

.give-padding {
  margin-left: -10px;
  margin-right: 15px; }

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="give-padding"] {
    margin-left: 0;
    margin-right: 0; } }

.controls-container {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }
  .controls-container .menu-container {
    display: flex;
    align-items: center; }

.no-margin {
  margin: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center; }
  .no-margin .shared-title {
    width: 90% !important;
    min-width: 120px !important; }
  .no-margin .title {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    min-width: 150px;
    width: 100%; }

.no-background {
  --background: transparent !important; }
  .no-background:hover {
    --background: transparent !important; }

.card-menu-title {
  display: flex !important;
  align-items: flex-start;
  justify-content: center;
  width: 100%; }

.kebabMenu {
  margin-left: auto;
  z-index: 9999;
  min-height: 24px; }

.small-width {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  --padding-start: 0;
  --padding-end: 0;
  --ion-color-primary: #fff;
  --ion-background-color: #413d45;
  padding-inline-end: 0; }
  .small-width .item-native .item-inner {
    padding-left: 0;
    padding-right: 0;
    padding-inline-start: 0;
    padding-inline-end: 0; }

.goal-card {
  border: 0;
  background: #fafafa;
  box-shadow: 0px 1px 2px 0px #1018280d; }

.shared {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background: #f8ba121a;
  color: #ff7e1d; }

.goal-card:hover {
  cursor: pointer; }
  .goal-card:hover .card-menu-title .title {
    color: #ff7e1d; }

.menu-button {
  background: transparent;
  color: #2b2930;
  --ion-color-primary: transparent; }

.in-active-goals {
  filter: grayscale(100%); }

.completed-goal-card {
  background: #ffc773;
  border-top: 3px solid #ffc773 !important; }
  .completed-goal-card .kebab-Menu {
    margin-left: auto;
    z-index: 9999;
    margin-top: 0.25rem; }
  .completed-goal-card .shared {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: #ff7e1d; }

.goal-completed-on {
  font-size: 0.8em !important;
  width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.blue-hover:hover {
  color: var(--ion-color-tertiary) !important; }

.give-padding {
  margin-left: -10px;
  margin-right: 15px; }
  @media only screen and (max-width: 768px) {
    .give-padding {
      /* For mobile phones: */ }
      .give-padding [class*="give-padding"] {
        margin-left: 0;
        margin-right: 0; } }
  .give-padding .completed-goal-card-wrapper-own-margin {
    width: 300px;
    margin: 0 25px 25px 0;
    padding: 3px 0 0;
    background: linear-gradient(90deg, #efb39d 0%, #ffde6e 100%);
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .give-padding .card-menu-title-completed {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .give-padding .card-header {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .give-padding .card-header .no-margin {
      margin: 0;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center; }
      .give-padding .card-header .no-margin .title {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        width: 100%; }
  @media only screen and (max-width: 768px) {
    .give-padding {
      /* For mobile phones: */ }
      .give-padding [class*="completed-goal-card-wrapper-own-margin"] {
        margin: 0 0 25px 0; } }

.create-goal-button {
  padding: 0;
  background-color: #0D6EF9;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  color: white;
  border-radius: 8rem;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  margin-right: 2rem; }

.complete-goals {
  background: #FFF1C4; }

.main-content-goals {
  margin-left: 2rem; }
  @media screen and (max-width: 600px) {
    .main-content-goals {
      margin-left: 0.5rem;
      margin-right: 0.5rem; } }

.header-goals {
  width: 100%;
  margin: 1rem 4rem 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem; }
  .cards-container .no-margin {
    margin: 0; }

.goal-routines,
.goal-task {
  margin-top: 24px;
  display: inline-block; }

.goal-routines-add-btn,
.goal-task-add-btn {
  margin-top: 18px;
  margin-bottom: 1em;
  display: inline-block;
  float: right; }

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px; }
  .title-container .add-btn {
    padding: 0;
    background: transparent; }

.goal-timeline {
  margin: 3rem auto;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem; }
  @media screen and (max-width: 768px) {
    .goal-timeline {
      flex-direction: column; } }

.routine-container {
  width: 48%;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem; }
  @media screen and (max-width: 768px) {
    .routine-container {
      width: 100%; } }

.desc {
  font-size: 0.9rem;
  font-weight: 400;
  margin: 1rem 0.5rem;
  text-wrap: wrap; }

.ant-select-selector {
  border-radius: 10rem !important;
  padding: 0 1.1rem !important;
  max-height: 30px; }

.ant-select-arrow {
  color: black; }

.gapS {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
  border-radius: 10rem; }

.active {
  color: #ffc773; }

.active-priority {
  color: black;
  font-weight: 500;
  position: relative;
  border: 1px solid #ffc773;
  border-radius: 20px;
  height: 32px;
  padding: 0 1rem; }

.priority-btn-active {
  border: 1px solid black;
  color: black; }

.priority-btn-inactive {
  border: 1px solid #d9d9d9;
  color: #939597; }

.priority-btn {
  position: relative;
  border-radius: 20px;
  height: 32px;
  padding: 0 1rem;
  background-color: #fff; }

.min-h-40vh {
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.priority-text {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  color: #000000d9; }

.status-active {
  color: #000000d9;
  border: 1px solid #000000d9;
  border-radius: 20px; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent; }

.status-completed {
  color: black;
  border-radius: 20px;
  background-color: #ffc773; }

.status-inactive {
  color: gray;
  border: 1px solid lightgray;
  border-radius: 20px; }

.priority-btn:hover {
  background-color: transparent; }

.goalNameInput {
  width: 100%; }

.no-button {
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer; }

.shared-btn {
  margin-top: 1rem;
  margin-left: 0.5rem; }

.flex-header {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }

.header-container {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  width: 100%; }
  .header-container .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    color: #2b2930; }
  .header-container .title-write {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    color: #2b2930;
    height: 100%;
    size: 100%;
    text-wrap: wrap;
    line-height: 32px; }

.no-left-padding {
  padding-inline-start: 0; }

.card-tags .ant-tag {
  color: #413D45 !important;
  background: #FAFAFA !important;
  padding: 0.25rem 0.8rem;
  border-radius: 6.25rem;
  border: none;
  text-transform: lowercase; }
  .card-tags .ant-tag::first-letter {
    text-transform: uppercase; }

.userDetailContainer {
  display: flex;
  align-items: center;
  text-wrap: wrap; }
  .userDetailContainer .avatar {
    min-width: 2rem; }
  .userDetailContainer .picture-avatar {
    border-radius: 50%;
    object-fit: cover;
    width: 60px;
    height: 60px;
    background: transparent; }
    .userDetailContainer .picture-avatar .ant-avatar-string {
      width: 100%; }
    .userDetailContainer .picture-avatar .avatar-img {
      height: 60px;
      width: 60px;
      border-radius: 50%; }
  .userDetailContainer .userDetail {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    font-size: 0.9rem;
    font-weight: 400; }
    .userDetailContainer .userDetail .userName {
      color: #413D45; }
    .userDetailContainer .userDetail .userEmail {
      color: #79777C; }

.profile-container {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }
  @media screen and (max-width: 720px) {
    .profile-container {
      flex-direction: column; } }

.reset-button {
  background: #FF7E1D;
  border-radius: 8rem;
  color: #fff;
  font-weight: 500;
  width: -webkit-max-content;
  width: max-content;
  padding: 0.8rem 1rem; }

.empty {
  text-decoration: none;
  color: #fff;
  white-space: nowrap; }
  .empty:hover {
    text-decoration: none;
    color: #fff !important; }

.flexBox {
  display: flex;
  align-items: center; }

.marginLeft {
  margin-top: -0.2rem;
  margin-left: 0.5rem; }

.ionic-button {
  --border-radius: 8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-size: 12px;
  background: #FAFAFA;
  border-radius: 8rem;
  white-space: nowrap;
  padding: 0.9rem 1rem; }
  .ionic-button:hover {
    background: #e6e6e6; }

.buttons-container {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem; }
  @media screen and (max-width: 1064px) and (min-width: 991px) {
    .buttons-container {
      flex-direction: column; } }
  @media screen and (max-width: 818px) and (min-width: 768px) {
    .buttons-container {
      flex-direction: column; } }

.team-info-container {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }
  @media screen and (max-width: 500px) {
    .team-info-container {
      flex-direction: column; } }

.remove-link-account-button {
  margin-left: auto;
  z-index: 9999;
  min-height: 24px; }
  @media screen and (max-width: 500px) {
    .remove-link-account-button {
      margin-left: 0; } }

.support-container {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  height: 100%; }

.support-intro-text {
  width: 80%;
  padding-top: 2em; }
  .support-intro-text ul > li {
    list-style: disc; }

.center-text {
  text-align: center; }

.open-sans {
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center; }

.bar-chart-icon {
  margin-top: 120px;
  margin-bottom: 12px;
  font-size: 80px;
  color: var(--ion-color-medium); }

/* Google Fonts */
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Inter", sans-serif;
  font-family: "Inter", sans-serif;
  font-weight: 400; }
  :root ::selection {
    background-color: #37343d;
    color: #fff; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

body {
  font-family: "Inter", sans-serif;
  font-weight: 400; }

/* New Color Creator: https://ionicframework.com/docs/theming/colors */
:root {
  /** primary **/
  --ion-color-primary: #2b2930;
  --ion-color-primary-rgb: 255, 87, 71;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2b2930;
  --ion-color-primary-tint: #2b2930;
  /** secondary **/
  --ion-color-secondary: #2b2930cc;
  --ion-color-secondary-rgb: 255, 179, 71;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #e09e3e;
  --ion-color-secondary-tint: #ffbb59;
  /** tertiary **/
  --ion-color-tertiary: #4793ff;
  --ion-color-tertiary-rgb: 71, 147, 255;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #3e81e0;
  --ion-color-tertiary-tint: #599eff;
  /** quarternary **/
  --ion-color-quarternary: #4f4df2;
  --ion-color-quarternary-rgb: 79, 77, 242;
  --ion-color-quarternary-contrast: #fff;
  --ion-color-quarternary-contrast-rgb: 255, 255, 255;
  --ion-color-quarternary-shade: #4f4df2;
  --ion-color-quarternary-tint: #4f4df2;
  --ion-color-success: #52c41a;
  --ion-color-success-rgb: 82, 196, 26;
  --ion-color-success-contrast: #000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #48ac17;
  --ion-color-success-tint: #63ca31;
  --ion-color-warning: #faad14;
  --ion-color-warning-rgb: 250, 173, 20;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dc9812;
  --ion-color-warning-tint: #fbb52c;
  --ion-color-danger: #e75d4a;
  --ion-color-danger-rgb: 245, 34, 45;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e75d4a;
  --ion-color-danger-tint: #e75d4a;
  /** dark **/
  --ion-color-dark: #323232;
  --ion-color-dark-rgb: 50, 50, 50;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #2c2c2c;
  --ion-color-dark-tint: #474747;
  /** medium-dark **/
  --ion-color-medium-dark: #646464;
  --ion-color-medium-dark-rgb: 100, 100, 100;
  --ion-color-medium-dark-contrast: #fff;
  --ion-color-medium-dark-contrast-rgb: 255, 255, 255;
  --ion-color-medium-dark-shade: #585858;
  --ion-color-medium-dark-tint: #747474;
  /** medium **/
  --ion-color-medium: #969696;
  --ion-color-medium-rgb: 150, 150, 150;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #848484;
  --ion-color-medium-tint: #a1a1a1;
  /** medium-light **/
  --ion-color-medium-light: #f4f3fe;
  --ion-color-medium-light-rgb: 200, 200, 200;
  --ion-color-medium-light-contrast: #000;
  --ion-color-medium-light-contrast-rgb: 0, 0, 0;
  --ion-color-medium-light-shade: #b0b0b0;
  --ion-color-medium-light-tint: #cecece;
  /** light **/
  --ion-color-light: #fafafa;
  --ion-color-light-rgb: 250, 250, 250;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dcdcdc;
  --ion-color-light-tint: #fbfbfb; }

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint); }

.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary);
  --ion-color-base-rgb: var(--ion-color-secondary-rgb);
  --ion-color-contrast: var(--ion-color-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-shade);
  --ion-color-tint: var(--ion-color-secondary-tint); }

.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary);
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary-shade);
  --ion-color-tint: var(--ion-color-tertiary-tint); }

.ion-color-quarternary {
  --ion-color-base: var(--ion-color-quarternary);
  --ion-color-base-rgb: var(--ion-color-quarternary-rgb);
  --ion-color-contrast: var(--ion-color-quarternary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quarternary-contrast-rgb);
  --ion-color-shade: var(--ion-color-quarternary-shade);
  --ion-color-tint: var(--ion-color-quarternary-tint); }

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark);
  --ion-color-base-rgb: var(--ion-color-dark-rgb);
  --ion-color-contrast: var(--ion-color-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-shade);
  --ion-color-tint: var(--ion-color-dark-tint); }

.ion-color-medium-dark {
  --ion-color-base: var(--ion-color-medium-dark);
  --ion-color-base-rgb: var(--ion-color-medium-dark-rgb);
  --ion-color-contrast: var(--ion-color-medium-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-dark-shade);
  --ion-color-tint: var(--ion-color-medium-dark-tint); }

.ion-color-medium {
  --ion-color-base: var(--ion-color-medium);
  --ion-color-base-rgb: var(--ion-color-medium-rgb);
  --ion-color-contrast: var(--ion-color-medium-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-shade);
  --ion-color-tint: var(--ion-color-medium-tint); }

.ion-color-medium-light {
  --ion-color-base: var(--ion-color-medium-light);
  --ion-color-base-rgb: var(--ion-color-medium-light-rgb);
  --ion-color-contrast: var(--ion-color-medium-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-light-shade);
  --ion-color-tint: var(--ion-color-medium-light-tint); }

.ion-color-light {
  --ion-color-base: var(--ion-color-light);
  --ion-color-base-rgb: var(--ion-color-light-rgb);
  --ion-color-contrast: var(--ion-color-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-shade);
  --ion-color-tint: var(--ion-color-light-tint); }

/* Grid */
:root {
  --space-unit: 16px;
  --space-xxs: calc(0.25 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --font-sm: 0.85rem;
  --space-md: calc(1.25 * var(--space-unit));
  --space-mdlg: calc(1.5 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3 * var(--space-unit));
  --space-xxl: calc(4 * var(--space-unit)); }

/* This will affect the scrollbar globally */
* {
  scrollbar-width: thin;
  scrollbar-color: #413d45 #fafafa; }

/* For Webkit browsers like Chrome, Safari */
*::-webkit-scrollbar {
  width: 8px; }

*::-webkit-scrollbar-track {
  background: #fafafa; }

*::-webkit-scrollbar-thumb {
  background-color: #413d45;
  border-radius: 20px;
  border: 3px solid #fafafa; }

.mont {
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 600; }

.montX {
  font-family: "Inter", sans-serif;
  letter-spacing: 0.1rem;
  font-weight: 600; }

.label {
  color: #4b4a4d;
  font-size: var(--font-sm); }

.ant-legacy-form-item {
  margin-bottom: 1rem; }

.mlr-auto {
  margin: 0 auto; }

.w-m-850 {
  max-width: 1100px; }

.mt-2 {
  margin-top: 2rem; }

.SiteHeader {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  font-weight: 600;
  flex-grow: 1; }

.XL {
  font-size: 18px; }

.L {
  font-size: 16px; }

.M {
  font-size: 0.9rem; }

.S {
  font-size: 10px; }

.formLabel {
  margin-bottom: 0px; }

.team-name {
  color: #000000;
  font-weight: 500;
  font-size: 1rem;
  margin: 0; }

.small-title {
  font-weight: bold; }

.subtitle {
  color: var(--ion-color-medium); }

.inline-alert {
  display: inline-block; }

.longform-text {
  width: 50%;
  background-color: var(--ion-color-light);
  padding: 2%;
  margin: 24px auto; }

.bold-text {
  font-weight: bold; }

.privacy-term-page {
  width: 100%;
  padding: 2%;
  margin: 48px auto; }
  .privacy-term-page a {
    color: blue; }
  .privacy-term-page a:hover {
    color: blue;
    text-decoration: underline; }
  .privacy-term-page hr {
    background-color: #ccc;
    margin: 28px auto; }
  .privacy-term-page code {
    color: #198138; }
  .privacy-term-page ol,
  .privacy-term-page ul {
    margin: 8px auto; }
  .privacy-term-page ul > li {
    list-style: disc;
    list-style-type: disc; }
  .privacy-term-page ol > li {
    list-style: decimal;
    list-style-type: decimal; }
  .privacy-term-page ol > li::marker {
    font-weight: 500; }
  .privacy-term-page .font-bold {
    font-weight: bold; }

@media only screen and (min-width: 992px) {
  .privacy-term-page {
    width: 70%;
    margin: 24px auto; } }

@media only screen and (min-width: 1360px) {
  .privacy-term-page {
    width: 50%; } }

ion-toolbar {
  --background: var(--ion-color-primary);
  --padding-start: var(--space-lg);
  --padding-end: var(--space-lg);
  height: var(--space-xxl);
  display: flex; }

.delete-popover {
  border-radius: 0.5rem; }

.no-background {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem; }

.ant-result-extra {
  margin-top: 1rem; }

.ant-result {
  padding: 2rem; }

.goal-modal {
  --width: 450px;
  --height: 410px; }

.new-goal-modal {
  --width: 450px;
  --height: 350px; }

.goal-modal-complete {
  --width: 450px;
  --height: 460px; }

.goal-review-card {
  background-color: #fafafa;
  max-width: 850px;
  border-radius: 0.5rem;
  border: none; }

.activity-modal {
  --width: 450px;
  --height: 400px !important; }

.routine-modal {
  --width: 450px;
  --height: 450px !important; }

.routine-progress-modal {
  --height: 525px !important; }
  @media screen and (max-width: 768px) {
    .routine-progress-modal {
      --width: 90vw !important; } }

.flex-wrap {
  flex-wrap: wrap; }

ion-select::part(icon) {
  display: none !important; }

/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* UserProfile.tsx */
  .profile-card {
    width: 80%;
    padding: 2rem;
    border-radius: 0.5rem; }
  .small-profile-card {
    width: 80%;
    padding: 1rem 2rem;
    border-radius: 0.5rem; } }

/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* Set variables of the entire app for iOS and Material Design */
.ios,
.md {
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff; }

.header-content-wrapper {
  align-items: center; }

ion-title {
  padding: 0; }

ion-page {
  background-color: #ffffff;
  padding: 0 var(--space-mdlg);
  min-height: 700px; }

.scroll-page {
  overflow-y: scroll; }

.dashboard {
  padding: 0 var(--space-mdlg); }

a {
  color: var(--ion-color-medium-dark); }
  a:hover {
    color: var(--ion-color-primary); }

.primary-link {
  color: var(--ion-color-primary);
  font-family: "Inter", sans-serif; }
  .primary-link:hover {
    color: var(--ion-color-medium-dark); }

.navbar-link {
  color: var(--ion-toolbar-color);
  font-family: "Inter", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 20px;
  display: flex;
  align-items: center; }
  .navbar-link:hover {
    color: var(--ion-toolbar-color); }

.celebration-gif {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0.5;
  width: calc(6.25 * var(--space-xxl)); }

.goalden-avatar {
  color: var(--ion-color-primary);
  background-color: #ffddda; }

.menu-content {
  background: #fafafa;
  transform: none; }

ion-button {
  --border-radius: 8rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-size: 12px;
  text-transform: none;
  --padding-top: 1.25rem !important;
  --padding-bottom: 1.25rem !important;
  --padding-start: 1.5rem;
  --padding-end: 1.5rem; }

.button-solid {
  --box-shadow: none; }

.plus-button {
  height: calc(1.25 * var(--space-mdlg));
  width: calc(1.25 * var(--space-mdlg)) !important;
  font-size: calc(1.25 * var(--space-mdlg));
  color: #ffc773;
  background-color: #fff;
  border-radius: 50%;
  z-index: 50; }
  .plus-button:hover {
    opacity: 0.85;
    cursor: pointer; }

.icon-button {
  color: var(--ion-color-primary); }

.x-button {
  border-radius: 15px;
  height: calc(1.25 * var(--space-mdlg));
  width: calc(1.25 * var(--space-mdlg));
  background-color: var(--ion-color-light);
  font-size: 12px; }
  .x-button:hover {
    opacity: 0.6; }

.button-img {
  cursor: pointer;
  vertical-align: top; }
  .button-img:hover {
    opacity: 0.8; }
  .button-img:active, .button-img:focus {
    transform: translate(0px, 1px); }

.text-button {
  color: var(--ion-color-primary);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  letter-spacing: 0.1rem;
  font-weight: 500; }

.completed {
  color: var(--ion-color-tertiary) !important; }

li {
  list-style: none; }

.header-md::after {
  height: 0px; }

.split-pane-md.split-pane-visible > .split-pane-side {
  max-width: 15%; }

ion-modal {
  --border-radius: 7px !important;
  --min-height: 380px; }

.modal-content {
  overflow-y: auto; }
  .modal-content .x-button {
    float: right; }

.modal-wrapper {
  min-height: 50px !important; }

.panes {
  height: 100%;
  width: 100%;
  display: flex; }
  .panes .pane {
    height: 100%;
    margin: 0px;
    flex-grow: 1;
    border-right: var(--border); }

hr {
  background-color: var(--ion-color-light); }

.checkIn {
  padding: 0.5rem 0.9rem;
  font-size: 0.9rem;
  border-radius: 6.25rem;
  font-weight: 500;
  background: #2b2930;
  color: #ffffff; }

.flexBoxRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }

.justify-content-center {
  justify-content: center; }

.routine-form-item-text {
  color: #79777c; }

.routine-form-item {
  color: #413d45;
  font-size: 14px;
  font-weight: 500; }

.masonry-grid {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem; }
  .masonry-grid ion-card {
    margin: 0;
    max-width: none; }

.masonry-grid-column {
  width: 100%; }

.masonry-grid-column ion-card {
  margin-bottom: 25px;
  /* match gutter */ }

.ant-list-items {
  border-bottom: 1px solid #e8e8e8; }

h4.ant-list-item-meta-title {
  margin-top: 0; }

.fancy-divider {
  font-style: italic;
  font-size: 14px !important; }

.nav-menu {
  background: #2b2930;
  height: 100vh;
  overflow: hidden;
  margin-bottom: 0; }
  .nav-menu .active-navlink {
    font-weight: 500; }
    .nav-menu .active-navlink li {
      background: #37343d;
      padding: 0.8rem 0 0.8rem 1rem;
      margin-right: 2rem;
      margin-left: -1rem;
      border-radius: 6.25rem; }
      .nav-menu .active-navlink li span {
        color: white;
        display: flex;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem; }
        .nav-menu .active-navlink li span .active {
          fill: #ff7e1d; }
  .nav-menu .active {
    fill: #ffffff8a; }
  .nav-menu li {
    font-size: var(--space-unit);
    padding: 0.8rem 0 0.8rem 1rem;
    margin-right: 2rem;
    margin-left: -1rem;
    margin-bottom: 1rem;
    color: rgba(255, 255, 255, 0.54); }
    .nav-menu li span {
      display: flex;
      align-items: center;
      grid-gap: 1rem;
      gap: 1rem; }
    .nav-menu li .anticon {
      margin-right: 6px; }

.active {
  fill: #ffffff8a;
  z-index: 2; }

.bubble-container {
  position: fixed;
  height: 40%;
  max-height: 280px;
  width: 100%;
  bottom: 0;
  overflow: hidden; }
  @media screen and (min-width: 1600px) {
    .bubble-container {
      max-height: none; } }
  @media screen and (max-height: 799px) {
    .bubble-container {
      display: none; } }
  .bubble-container .inner-circle-one {
    height: 300%;
    width: 300%;
    background-color: #2d2b32;
    border-radius: 50%;
    position: absolute;
    left: -150%; }
    .bubble-container .inner-circle-one .inner-circle-two {
      height: 100%;
      width: 100%;
      background-color: #2f2d34;
      border-radius: 50%;
      position: absolute;
      left: -5%;
      top: 8%;
      box-shadow: 0px 5px 10px 0px #1d1e21; }
      .bubble-container .inner-circle-one .inner-circle-two .inner-circle-three {
        height: 75%;
        width: 75%;
        background-color: #312f36;
        border-radius: 50%;
        position: absolute;
        top: 10%;
        left: 15%;
        box-shadow: 0px 5px 10px 0px #1d1e21; }

.goal-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 0.5rem; }
  @media screen and (max-width: 600px) {
    .goal-form-wrapper {
      padding-left: 1.5rem; } }
  .goal-form-wrapper .goal-form {
    width: 400px;
    min-width: 200px; }
    @media screen and (max-width: 600px) {
      .goal-form-wrapper .goal-form {
        width: 95%; } }
    .goal-form-wrapper .goal-form .form-buttons-wrapper {
      display: flex; }
      .goal-form-wrapper .goal-form .form-buttons-wrapper .cancel-button {
        margin-left: auto;
        margin-right: 1rem;
        font-size: var(--font-sm);
        color: #413d45;
        background: #fafafa;
        padding: 0.8rem 1rem;
        border: none;
        border-radius: 100px;
        display: flex;
        align-items: center; }
  .goal-form-wrapper h3 {
    text-align: center;
    margin-bottom: var(--space-mdlg); }
  .goal-form-wrapper .ant-alert {
    width: 50%;
    margin: auto; }

ion-select {
  padding: 5px 0px; }

.alert-radio-label {
  font-size: 14px !important; }

.ant-input-mimic,
.ant-input-mimic-date,
.new-date-picker {
  width: 80%;
  border-radius: 100px !important;
  text-align: center;
  border: 1px solid;
  border-color: #d9d9d9;
  background: #fafafa;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  line-height: calc(1.5 * var(--space-sm));
  font-size: 14px;
  font-variant: tabular-nums;
  font-feature-settings: "tnum", "tunm", "tnum"; }

.ant-input-affix-wrapper {
  line-height: 1.9; }

.ant-input-mimic-date {
  width: 8rem !important;
  padding-right: 11px; }

.new-date-picker {
  width: 8rem !important;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem !important; }

.ant-input {
  color: #79777c !important;
  background: #fafafa;
  border-color: #e9e7e4; }
  .ant-input::placeholder {
    color: #79777c; }

.ant-input:hover {
  border-color: #e9e7e4; }

.ant-input:focus {
  border-color: #e9e7e4;
  box-shadow: 0 0 0 2px #e9e7e4; }

.ant-tooltip-inner {
  background-color: var(--ion-color-dark);
  font-family: "Inter", sans-serif; }

ion-card {
  max-width: calc(4.6875 * var(--space-xxl)); }

.card-tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }
  .card-tags .ant-tag {
    font-size: 0.75rem;
    font-weight: 500;
    margin-right: 6px;
    margin-top: 8px;
    white-space: nowrap; }

.card-button {
  position: absolute;
  right: 1rem;
  top: 1rem; }

.card-buttons {
  position: absolute;
  right: 1rem;
  z-index: 999; }
  .card-buttons span {
    margin-left: 8px; }

.goal-card {
  background: #ffffff;
  border-top: 0.5rem solid #ffc773;
  border-radius: 0.5rem;
  min-width: calc(5.2 * var(--space-xxl));
  width: 100%; }

.completed-goal-card {
  min-width: calc(5.2 * var(--space-xxl));
  width: 100%;
  border-top: 3px solid var(--ion-color-tertiary);
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #333; }

.goal-card-link :hover {
  color: orange; }

ion-card-header ion-card-title {
  font-weight: bold;
  font-family: "Inter", sans-serif; }
  ion-card-header ion-card-title a {
    color: var(--ion-color-dark); }
    ion-card-header ion-card-title a:hover {
      color: var(--ion-color-primary); }

ion-card-header ion-card-subtitle {
  color: var(--ion-color-medium); }

.goal-card-descr {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px !important;
  color: #2b2930;
  letter-spacing: 0; }

.card-links {
  display: flex;
  justify-content: space-between; }

.terms-optin {
  width: 110%;
  margin: 2rem 0 2rem -4%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65); }

.logo-link {
  grid-gap: 0.5rem;
  gap: 0.5rem; }

.check-in-inline-badge {
  display: flex;
  justify-content: flex-start; }

.check-in-badge .ant-badge-count {
  margin-left: 7px;
  margin-top: 3px;
  background-color: var(--ion-color-primary); }

.main-area {
  margin-top: 2rem; }

.main-content {
  margin: 0 4rem; }

.form-container {
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto; }
  .form-container h1 {
    margin-bottom: 24px; }
  .form-container .ant-alert {
    margin-bottom: 24px; }

.form-submit-button {
  font-family: "Inter", sans-serif;
  letter-spacing: 0.1rem;
  font-weight: 500;
  font-size: var(--font-sm);
  padding: 0.75rem 1rem;
  border: none;
  display: flex;
  align-items: center;
  background: #1877f2;
  color: #ffffff;
  border-radius: 8px; }

.logomark {
  width: calc(1.25 * var(--space-lg));
  height: calc(1.25 * var(--space-lg)); }

.logotype {
  color: #2b2930;
  padding-left: 8px; }

ul {
  list-style: none; }

.cards-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0; }

.center {
  margin-left: auto;
  margin-right: auto; }

.center-text {
  text-align: center; }

.left-align-text {
  text-align: left; }

.center-flex {
  display: flex;
  justify-content: center; }

.bump-top {
  margin-top: 10px; }

.bump-right {
  margin-right: 10px; }

.bump-bottom {
  margin-bottom: 10px; }

.bump-left {
  margin-left: 10px; }

.popover-content.sc-ion-popover-md {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .popover-content.sc-ion-popover-md .list-md {
    background: #413d45;
    padding: 0; }

.item-native {
  background: #413d45 !important; }
  .item-native .item-inner {
    padding-inline-end: 0 !important; }

.sc-ion-popover-md {
  background: transparent; }

.logoDashboard {
  margin-left: -1rem; }

.DashboardLeftLogo {
  margin: 3rem 0 3rem 0; }

.no-border-right {
  border-right: 0; }

.header {
  width: 100%;
  margin: 1rem 2rem 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.user-profile-header {
  width: 100%;
  margin: 1rem 2rem 2rem 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 760px) {
    .user-profile-header {
      margin: 1rem 2rem 2rem 1rem; } }

.plus-sign {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%; }

.plus-sign::before,
.plus-sign::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 2px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  border-radius: 2px; }

.plus-sign::before {
  transform: translate(-50%, -50%) rotate(90deg); }

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents; }

.IconWrapper {
  background: #f8ba121a;
  border-radius: 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.8rem 0.8rem;
  display: flex;
  align-items: center; }
  .IconWrapper .active {
    fill: #ff7e1d; }

.ant-btn-primary {
  background: #0d6ef9;
  border: #0d6ef9; }

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #0d6ef9;
  opacity: 0.75; }

.inspirationGoalTempCard {
  margin: 0;
  width: 100%; }

.template-card-header {
  padding: 0;
  text-align: left;
  text-transform: lowercase; }
  .template-card-header::first-letter {
    text-transform: uppercase; }

.goal-temp-card {
  border-top: 0.5rem solid #ffc773;
  padding: 1rem;
  background: #ffffff;
  border-radius: 0.5rem;
  max-width: none; }
  .goal-temp-card .temp-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin: 0 1rem; }
  .goal-temp-card .content-container {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0;
    margin-top: 0.5rem; }
    .goal-temp-card .content-container .img-container {
      height: auto;
      width: 100%; }

.goal-temp-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-bottom: 1rem; }

.tab-buttons {
  margin-left: auto;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: -0.5rem; }
  @media screen and (max-width: 500px) {
    .tab-buttons {
      max-width: 200px;
      margin-left: 0.5rem;
      overflow: auto; } }
  .tab-buttons .activeTab {
    background: #e9e7e4;
    color: #413d45; }
  .tab-buttons .inactiveTab {
    background: transparent;
    color: #9f9f9d; }
  .tab-buttons .activeTab,
  .tab-buttons .inactiveTab {
    padding: 0.4rem 0.75rem;
    border-radius: 6rem;
    font-weight: 600; }

.routine-temp-card {
  border-left: 0.5rem solid #8e24aa; }

.temp-card {
  padding: 1rem;
  background: #ffffff;
  border-radius: 0.5rem;
  max-width: none; }
  .temp-card .temp-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin: 0 1rem; }
  .temp-card .content-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 1rem; }

.task-temp-card {
  border-left: 0.5rem solid #613cb0; }

.routine-plus {
  color: #8e24aa; }

.task-plus {
  color: #613cb0; }

.closeSVG {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 0.5rem;
  border-radius: 100px;
  background: #e9e7e4;
  cursor: pointer; }

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin: 0 auto; }

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  padding: 0 auto; }

.not-found-small {
  height: 15vh;
  width: 100%; }

.shorterButton {
  min-height: 24px;
  padding: 0.2rem 0;
  overflow: hidden; }

.dropdown-up .ant-select-dropdown {
  transform: scaleY(-1); }

.select-option {
  text-align: center; }
  .select-option ::selection {
    background: #ffc773; }

.date-picker-dropdown {
  border-color: #d9d9d9 !important;
  background: #fafafa;
  box-shadow: none !important; }

.ant-select-selector,
.date-picker-select {
  border-color: #d9d9d9 !important;
  background: #fafafa;
  box-shadow: none !important;
  border-radius: 1.5rem; }

.date-picker-select {
  width: 8rem !important;
  text-align: center; }

.fullWidth {
  width: 100%; }

.plus-minus-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100rem !important;
  background: #1877f214 !important;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0.3rem 0.55rem;
  color: #0d6ef9 !important;
  border: none; }
  .plus-minus-button:hover {
    color: #0d6ef9 !important;
    background: #1877f214 !important;
    opacity: 0.75 !important; }

.routine-form-input {
  width: 4rem !important;
  padding: 0.25rem 0.75rem;
  text-align: center;
  border-radius: 0.5rem !important;
  border: 1px solid #e9e7e4 !important;
  box-shadow: none !important; }
  .routine-form-input .ant-btn:active, .routine-form-input .ant-btn:focus, .routine-form-input .ant-btn:hover, .routine-form-input .ant-btn:visited, .routine-form-input .ant-btn::after {
    border: 1px solid #e9e7e4 !important;
    border-color: #e9e7e4 !important;
    background: #fafafa !important;
    box-shadow: none !important; }
  .routine-form-input:active, .routine-form-input:focus, .routine-form-input:hover, .routine-form-input:visited, .routine-form-input::after {
    border: 1px solid #e9e7e4 !important;
    border-color: #e9e7e4 !important;
    background: #fafafa !important;
    box-shadow: none !important; }

@media screen and (max-width: 768px) {
  .main-content {
    margin: 0 1rem; }
  .create-goal-button {
    padding: 0; }
  .goal-modal {
    --width: 90vw;
    max-height: 100vh; } }

.step-title {
  font-size: 0.9rem;
  font-weight: 500;
  color: #413d45; }

.step-description {
  font-size: 0.75rem;
  font-weight: 400;
  color: #79777c; }

.orange-radio {
  color: #ff7e1d;
  background: #ff7e1d1a;
  border-radius: 6.25rem;
  padding: 0.4rem 1.2rem; }
  .orange-radio .ant-radio-inner {
    border-color: #ff7e1d; }
  .orange-radio .ant-radio-inner::after {
    background-color: #ff7e1d; }

.grey-radio {
  color: #d9d7d5;
  border-color: transparent !important; }
  .grey-radio .ant-radio-inner {
    border-color: #d9d7d5 !important; }

.white-bg {
  background: #fff; }

.orange-button {
  --ion-color-primary: #ff7e1d; }

.check-in-checkbox {
  position: absolute;
  left: -2rem;
  height: 1rem !important;
  opacity: 1 !important;
  width: 1rem !important; }

.delete-button {
  background: #e75d4a;
  padding: 0.15rem 0.6rem;
  border-radius: 6.25rem; }

.ant-popover-inner-content {
  font-family: "Inter", sans-serif; }

.ant-btn-sm {
  border-radius: 4px;
  padding: 0 1rem;
  height: 32px; }

.collective-routines {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  column-gap: 2rem;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  margin-top: 1.5rem; }
  @media screen and (max-width: 640px) {
    .collective-routines {
      grid-template-columns: repeat(1, 1fr); } }
  @media screen and (min-width: 641px) and (max-width: 1400px) {
    .collective-routines {
      grid-template-columns: repeat(2, 1fr); } }

.logo-title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500; }
  @media screen and (max-width: 768px) {
    .logo-title {
      font-size: 16px; } }

.goal-text-detail-form {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.2rem; }
  @media screen and (max-width: 450px) {
    .goal-text-detail-form {
      max-width: 125px; } }

.goal-text {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.2rem; }
  @media screen and (max-width: 450px) {
    .goal-text {
      max-width: 150px; } }

.goal-text-select {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.7rem; }

.alert-radio-label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  line-height: 1.4;
  white-space: unset !important; }

.alert-radio-group.sc-ion-alert-md,
.alert-checkbox-group.sc-ion-alert-md {
  border-top: 0px !important;
  border-bottom: 0px !important; }

.alert-head.sc-ion-alert-md {
  padding-inline-start: 0px !important;
  padding-top: 0px !important;
  --min-width: max-content; }

.alert-button-group.sc-ion-alert-md {
  border-top: 1px solid #d9d9d9;
  padding-top: 0px;
  margin-top: 1rem; }

.no-goal-selected {
  border: 1px solid #ff7d6b;
  border-radius: 0.5rem;
  color: #ff7d6b !important; }

.error-container {
  position: absolute;
  left: 0;
  right: 0; }

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.min-w-max {
  min-width: -webkit-max-content;
  min-width: max-content; }

.min-w-min {
  min-width: -webkit-min-content;
  min-width: min-content; }

.flex {
  display: flex; }

.items-center {
  align-items: center; }

.access-code-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; }
  .access-code-page .form-container {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center; }
  .access-code-page .access-code-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0.5rem; }
  .access-code-page .access-code-subheader {
    font-size: 16px;
    color: #6c757d;
    margin-bottom: 1.5rem; }
  .access-code-page .input-container {
    margin-bottom: 1rem; }
    .access-code-page .input-container .input-access-code {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      border-radius: 5px; }
  .access-code-page .button-container {
    margin-top: 1rem; }
    .access-code-page .button-container .form-submit-button {
      width: 100%;
      font-size: 16px;
      padding: 20px;
      border-radius: 5px;
      justify-content: center;
      align-items: center; }


.create-goal-button {
    padding: 0;
    background-color: #0D6EF9;
    display: flex;
    width: fit-content;
    align-items: center;
    color: white;
    border-radius: 8rem;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
    margin-right: 2rem;
}

.complete-goals{
    background: #FFF1C4;
}

.main-content-goals{
    margin-left: 2rem;
    @media screen and (max-width: 600px){
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}
.header-goals {
    width: 100%;
    margin: 1rem 4rem 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.cards-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .no-margin{
        margin: 0;
    }
}  